import { store } from 'lib/store';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const isLoggedIn = store.getState().session.isAuthenticated;

  return isLoggedIn ? children : <Navigate to='/auth/sign-in' replace />;
};

export default PrivateRoute;
