import { createHttpRequests } from 'api/requests';
import { LessonsSubjectsRequest, LessonsSubjectsResponse } from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest } = createHttpRequests(axiosServices);

export const getLessonsSubjects = async (data: LessonsSubjectsRequest): Promise<LessonsSubjectsResponse | any> => {
  try {
    const response = await getRequest<LessonsSubjectsResponse>(endpoints.lessonsSubjects, { ...data });
    return response;
  } catch (error) {
    return error;
  }
};
