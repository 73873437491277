import { me } from 'api/services/auth';
import { MeResponse } from 'api/services/auth/types';
import { getNotifications } from 'api/services/notifications';
import { NotificationsResponse } from 'api/services/notifications/types';
import { getCookie } from 'helpers/cookie-manager';
import { setNotifications } from 'lib/slices/notifications/notifications-slice';
import { setIsLoading, setSession } from 'lib/slices/session/session-slice';
import { AppStore, store } from 'lib/store';
import { ReactNode, useRef } from 'react';
import { Provider } from 'react-redux';

export default function StoreProvider({ children }: { children: ReactNode }) {
  const storeRef = useRef<AppStore>();

  if (!storeRef.current) {
    storeRef.current = store;

    if (storeRef.current) {
      const fetchUser = async () => {
        const data: MeResponse = await me();
        const notifications = await getNotifications();

        if (data && storeRef.current) {
          storeRef.current.dispatch(
            setSession({
              isAuthenticated: true,
              role: data.roles[0],
              email: data.email,
              username: data.username,
              organization: data.organization,
              modules: data.modules,
              nameSurname: data.nameSurname
            })
          );

          if (Array.isArray(notifications) && notifications.length > 0) {
            const mappedNotifications = (notifications as NotificationsResponse).map((notitication) => {
              return { message: notitication.message };
            });

            storeRef.current.dispatch(
              setNotifications({
                notifications: mappedNotifications
              })
            );
          }
        }
      };

      if (getCookie('accessToken')) {
        fetchUser();
      } else {
        storeRef.current.dispatch(setIsLoading(false));
      }
    }
  }

  return <Provider store={storeRef.current}>{children}</Provider>;
}
