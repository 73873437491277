import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CreateOrEditSalonModalProps, CreateOrEditSalonModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { createSalon, updateSalon } from 'api/services/salons';
import { CreateSalonRequest, UpdateSalonRequest } from 'api/services/salons/types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { useParams } from 'react-router-dom';
import { cancelReservation } from 'api/services/reservations';

export default function CancelReservationModal(props: CreateOrEditSalonModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { onSuccessCallback, reservationId } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: CreateOrEditSalonModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditSalonModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      const cancelReservationResponse = await cancelReservation(reservationId, values.cancelReason);

      if (cancelReservationResponse?.data) {
        throw cancelReservationResponse?.data;
      }

      snackbar('Başarıyla iptal edildi', 'success');
      onSuccessCallback();
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        cancelReason: ''
      }}
      validationSchema={Yup.object().shape({
        cancelReason: Yup.string().max(200).required('İptal mesajı zorunlu')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field name='cancelReason'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={Boolean(form.errors.cancelReason && form.touched.cancelReason)}>
                <FormLabel>İptal Mesajı</FormLabel>
                <Textarea {...field} placeholder='Rezervasyon iptal mesajınızı yazınız.' resize='none' />
                <FormErrorMessage>{form.errors.cancelReason as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
