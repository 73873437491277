const createCookie = (name: string, value: string, days: number, secure = true) => {
  if (typeof document !== 'undefined') {
    let expires = '';
    let https = '';

    if (days) {
      const date = new Date();
      const expiryInMilliSeconds = 86400000;

      date.setTime(date.getTime() + days * expiryInMilliSeconds);
      expires = `; expires=${date.toUTCString()}`;
    }

    // eslint-disable-next-line no-restricted-globals
    if (secure && location.protocol === 'https:') {
      https = ';secure';
    }

    document.cookie = `${name}=${value}${expires}; path=/${https}`;
  }
};

export const setCookie = (name: string, value: string) => {
  try {
    if (typeof value === 'object') {
      value = encodeURIComponent(JSON.stringify(value));
    }
    createCookie(name, value, 1);
  } catch (e) {
    throw e;
  }
};

export const eraseCookie = (name: string) => {
  createCookie(name, '', -1);
};

const readCookie = (name: string) => {
  let value;

  if (typeof document !== 'undefined') {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        value = c.substring(nameEQ.length, c.length);
      }
    }
  }
  return value;
};

export const getCookie = (name: string) => readCookie(name);
