import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { CreateOrEditSalonModalProps, CreateOrEditSalonModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { createSalon, updateSalon } from 'api/services/salons';
import { CreateSalonRequest, UpdateSalonRequest } from 'api/services/salons/types';
import { useCustomSnackbar } from 'utils/custom-snackbar';

export default function CreateOrEditSalonModal(props: CreateOrEditSalonModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { salon, onSuccessCallback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: CreateOrEditSalonModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditSalonModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      if (salon?.id) {
        const payload: UpdateSalonRequest = {
          name: values.name
        };

        const updateSalonResponse = await updateSalon(payload, salon.id.toString());

        if (updateSalonResponse.status === 400) {
          if (Array.isArray(updateSalonResponse.data)) {
            const errorMessages = updateSalonResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(updateSalonResponse.data);
        }

        if (!updateSalonResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla güncellendi', 'success');
        onSuccessCallback();
      } else {
        const payload: CreateSalonRequest = {
          name: values.name
        };

        const createSalonResponse = await createSalon(payload);

        if (createSalonResponse.status === 400) {
          if (Array.isArray(createSalonResponse.data)) {
            const errorMessages = createSalonResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(createSalonResponse.data);
        }

        if (!createSalonResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla oluşturuldu', 'success');
        onSuccessCallback();
      }
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        name: salon?.name || ''
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('İsim zorunlu')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ errors, handleChange, values, handleSubmit, touched }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl isInvalid={touched.name && !!errors.name}>
            <FormLabel>Salon Adı</FormLabel>
            <Input name='name' size='md' value={values.name} onChange={handleChange} _dark={{ color: 'white' }} />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
