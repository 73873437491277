import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { useUI } from 'contexts/UIContext';
import { FC, HTMLAttributes, ReactNode } from 'react';

interface CustomDialogProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  children?: ReactNode;
  onClose?: () => void;
  modalSize?: string;
}

const CustomDialog: FC<CustomDialogProps> = ({ title, children, modalSize }) => {
  const { hideModal } = useUI();

  return (
    <Modal onClose={hideModal} isOpen={true} isCentered size={modalSize || 'md'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={5}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomDialog;
