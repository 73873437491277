import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  SimpleGrid
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { ReservationRestrictionModalProps, ReservationRestrictionModalPropsSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { reservationRestriction } from 'api/services/students';
import { Select as MultiSelect } from 'chakra-react-select';
import { formatTimeWithSeconds } from 'helpers/date';

export const dayOptions = [
  { value: 'Monday', label: 'Pazartesi' },
  { value: 'Tuesday', label: 'Salı' },
  { value: 'Wednesday', label: 'Çarşamba' },
  { value: 'Thursday', label: 'Perşembe' },
  { value: 'Friday', label: 'Cuma' },
  { value: 'Saturday', label: 'Cumartesi' },
  { value: 'Sunday', label: 'Pazar' }
];

export default function ReservationRestrictionModal(props: ReservationRestrictionModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { classId, onSuccessCallback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: ReservationRestrictionModalPropsSubmit,
    { setSubmitting }: FormikHelpers<ReservationRestrictionModalPropsSubmit>
  ) => {
    try {
      setIsLoading(true);

      const payload = {
        reservationStartTime: values.reservationStartTime
          ? formatTimeWithSeconds(values.reservationStartTime)
          : undefined,
        reservationEndTime: values.reservationEndTime ? formatTimeWithSeconds(values.reservationEndTime) : undefined,
        availableDays: values.availableDays.length > 0 ? values.availableDays : undefined
      };

      await reservationRestriction(payload, classId);

      snackbar('Rezervasyon kısıtlaması başarıyla eklendi', 'success');
      return onSuccessCallback();
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        reservationStartTime: undefined,
        reservationEndTime: undefined,
        availableDays: []
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string(),
        reservationStartTime: Yup.string().optional(),
        reservationEndTime: Yup.string()
          .optional()
          .test('not-equal', 'Başlangıç ve Bitiş Saati aynı olamaz', function (value) {
            const { reservationStartTime } = this.parent;
            if (reservationStartTime && value) {
              return value !== reservationStartTime;
            }

            return true;
          }),
        availableDays: Yup.array()
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            <Field name='availableDays'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.availableDays && form.touched.availableDays)}>
                  <FormLabel>Günler</FormLabel>
                  <MultiSelect
                    isMulti
                    name='colors'
                    options={dayOptions}
                    placeholder='Günler'
                    closeMenuOnSelect={false}
                    value={dayOptions.filter((option) => field.value.includes(option.value))}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map((option) => option.value);
                      form.setFieldValue(field.name, selectedValues);
                    }}
                  />
                  <FormErrorMessage>{form.errors.availableDays as string}</FormErrorMessage>
                  <FormHelperText>Öğrencinin rezervasyon alabileceği günleri seçiniz.</FormHelperText>
                </FormControl>
              )}
            </Field>

            <SimpleGrid columns={2} spacing={5}>
              <Field name='reservationStartTime'>
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(form.errors.reservationStartTime && form.touched.reservationStartTime)}
                  >
                    <FormLabel>Başlangıç Saati</FormLabel>
                    <Input {...field} type='time' _dark={{ color: 'white' }} />
                    <FormErrorMessage>{form.errors.reservationStartTime as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name='reservationEndTime'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.reservationEndTime && form.touched.reservationEndTime)}>
                    <FormLabel>Bitiş Saati</FormLabel>
                    <Input {...field} type='time' _dark={{ color: 'white' }} />
                    <FormErrorMessage>{form.errors.reservationEndTime as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </SimpleGrid>

            <Button
              onClick={() => {
                setFieldValue('reservationStartTime', '');
                setFieldValue('reservationEndTime', '');
              }}
            >
              Saatleri Sıfırla
            </Button>
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
