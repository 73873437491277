import { store } from 'lib/store';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: JSX.Element;
}

const GuestRoute = ({ children }: PrivateRouteProps) => {
  const isLoggedIn = store.getState().session.isAuthenticated;

  return isLoggedIn ? <Navigate to='/panel/home' replace /> : children;
};

export default GuestRoute;
