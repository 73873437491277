// Chakra imports
import { Flex, Stat, StatLabel, StatNumber, useColorModeValue, Text } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { ControlledMiniStaticticsProps } from './types';
import { useAppSelector } from 'lib/hooks';

export default function ControlledMiniStatictics(props: ControlledMiniStaticticsProps) {
  const { startContent, endContent, name, growth, value, module, roles } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  const organizationModules = useAppSelector((selector) => selector.session.modules);
  const userRole = useAppSelector((selector) => selector.session.role);

  const isAllowed =
    (!module || organizationModules.includes(module)) && (!Array.isArray(roles) || roles.includes(userRole));

  if (!isAllowed) {
    return null;
  }

  return (
    <Card py='25px'>
      <Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
        {startContent}

        <Stat my='auto' ms={startContent ? '18px' : '0px'}>
          <StatLabel
            lineHeight='100%'
            color={textColorSecondary}
            fontSize={{
              base: 'md'
            }}
          >
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: '4xl'
            }}
          >
            {value}
          </StatNumber>
          {growth ? (
            <Flex align='center'>
              <Text color='green.500' fontSize='xs' fontWeight='700' me='5px'>
                {growth}
              </Text>
              <Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
                since last month
              </Text>
            </Flex>
          ) : null}
        </Stat>
        <Flex ms='auto' w='max-content'>
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
