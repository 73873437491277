import { createHttpRequests } from 'api/requests';
import {
  ClassesResponse,
  CreateClassRequest,
  CreateClassResponse,
  GetClassResponse,
  UpdateClassRequest,
  UpdateClassResponse
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getClasses = async (data?: { page: string; pageSize: string }): Promise<ClassesResponse | any> => {
  try {
    const response = await getRequest<ClassesResponse>(endpoints.classes, {
      page: data.page || '1',
      pageSize: data.pageSize || '10'
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getClass = async (id: string): Promise<GetClassResponse | any> => {
  try {
    const response = await getRequest<GetClassResponse>(endpoints.class(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const createClass = async (data: CreateClassRequest): Promise<CreateClassResponse | any> => {
  try {
    const response = await postRequest<CreateClassResponse>(endpoints.classes, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateClass = async (data: UpdateClassRequest, id: string): Promise<UpdateClassResponse | any> => {
  try {
    const response = await putRequest<UpdateClassResponse>(endpoints.class(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteClass = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.class(id));
    return response;
  } catch (error) {
    return error;
  }
};
