import { Modal, ModalOverlay, Spinner } from '@chakra-ui/react';
import CustomDialog from 'components/shared/dialog';
import { createContext, useContext, useState, ReactNode, ReactElement, FC } from 'react';

interface UIContextType {
  isVisible: boolean;
  content: ReactNode | null;
  title: string;
  showModal: (component: ReactNode, title?: string, modalSize?: string) => void;
  hideModal: () => void;
  showLoading: () => void;
  hideLoading: () => void;
}

const UIContext = createContext<UIContextType>({
  isVisible: false,
  content: null,
  title: '',
  showModal: () => {},
  hideModal: () => {},
  showLoading: () => {},
  hideLoading: () => {}
});

export const useUI = () => useContext(UIContext);

export const UIProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [modalSize, setModalSize] = useState<string>('');

  const showModal = (component: ReactNode, title?: string, modalSize?: string) => {
    setContent(component);
    setIsVisible(true);
    setTitle(title || '');
    setModalSize(modalSize || '');
  };

  const hideModal = () => {
    setIsVisible(false);
    setContent(null);
    setTitle('');
  };

  const showLoading = () => setLoadingVisible(true);
  const hideLoading = () => setLoadingVisible(false);

  return (
    <UIContext.Provider value={{ showModal, hideModal, content, isVisible, title, showLoading, hideLoading }}>
      {children}
      {/* {loadingVisible && ( */}
      <Modal onClose={hideModal} isOpen={loadingVisible} isCentered>
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1400' }}>
          <ModalOverlay />
          <Spinner size='xl' />
        </div>
      </Modal>
      {/* )} */}
      {isVisible && (
        <CustomDialog title={title || ''} onClose={hideModal} modalSize={modalSize}>
          {content}
        </CustomDialog>
      )}
    </UIContext.Provider>
  );
};
