import { createHttpRequests } from 'api/requests';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';
import { NotificationsResponse } from './types';

const { putRequest, getRequest } = createHttpRequests(axiosServices);

export const getNotifications = async (): Promise<NotificationsResponse | any> => {
  try {
    const response = await getRequest<NotificationsResponse>(endpoints.notifications);
    return response;
  } catch (error) {
    return error;
  }
};

export const markAsReadNotifications = async (): Promise<any | any> => {
  try {
    const response = await putRequest<any>(endpoints.readNotifications);
    return response;
  } catch (error) {
    return error;
  }
};
