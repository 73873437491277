import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { deleteSalon, getSalon, getSalons } from 'api/services/salons';
import CreateOrEditSalonModal from 'components/salons/create-or-edit-salon-modal';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useEffect, useState } from 'react';
import { SalonsRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { GetSalonResponse, SalonsResponse } from 'api/services/salons/types';

const columnsDataCheck: Column<SalonsRowObj>[] = [{ name: 'name', displayName: 'Salon Adı' }];

export default function Salons() {
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [salons, setSalons] = useState<SalonsResponse>({ totalCount: 0, salons: [] });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchSalons = async (page?: number, pageSize?: number) => {
    try {
      const salonsResponse = await getSalons({ page: page?.toString(), pageSize: pageSize?.toString() });

      setSalons(salonsResponse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchSalons(page, pageSize);
  };

  useEffect(() => {
    fetchSalons(page, pageSize);
  }, []);

  const handleClickCreateSalon = () => {
    showModal(<CreateOrEditSalonModal onSuccessCallback={handleOnSuccessCallback} />, 'Soru Çözüm Salonu Oluştur');
  };

  const handleEdit = async (id: number) => {
    try {
      const salonResponse: GetSalonResponse = await getSalon(id.toString());

      showModal(
        <CreateOrEditSalonModal salon={salonResponse} onSuccessCallback={handleOnSuccessCallback} />,
        'Soru Çözüm Salonu Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const deleteResponse = await deleteSalon(id.toString());

      if (deleteResponse?.status === 400) {
        throw new Error(deleteResponse.data);
      }

      snackbar('Başarıyla silindi', 'success');
      await fetchSalons(page, pageSize);
    } catch (err: any) {
      snackbar(err.message, 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchSalons(pageIndex + 1, pageSize);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
            onClick={handleClickCreateSalon}
          >
            Yeni Ekle
          </ControlledButton>
        </Flex>
        <DataTable
          tableData={salons.salons}
          totalCount={salons.totalCount}
          onPaginationChangeCallback={onPaginationChange}
          columns={columnsDataCheck}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
          loading={loading}
        />
      </SimpleGrid>
    </Box>
  );
}
