import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationsState } from './types';

const initialState: NotificationsState = {
  notifications: []
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<NotificationsState>) => {
      state.notifications = action.payload.notifications;
    },
    resetNotifications: () => {
      return initialState;
    }
  }
});

export const { setNotifications, resetNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
