import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { SnackbarProvider } from 'notistack';
import { UIProvider } from 'contexts/UIContext';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from 'store-provider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StoreProvider>
    <BrowserRouter>
      <ChakraProvider>
        <SnackbarProvider classes={{ containerRoot: 'z-alert' }}>
          <UIProvider>
            <App />
          </UIProvider>
        </SnackbarProvider>
      </ChakraProvider>
    </BrowserRouter>
  </StoreProvider>
);
