import { createHttpRequests } from 'api/requests';
import {
  ReservationsResponse,
  CreateReservationRequest,
  CreateReservationResponse,
  UpdateReservationRequest,
  UpdateReservationResponse,
  GetReservationResponse,
  JoinReservationRequest,
  AttendanceReservationRequest,
  ReservationLessonsResponse
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';
import { buildQueryString, extendedBuildQueryString } from 'helpers/url';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getReservations = async (data?: {
  page: string;
  size: string;
  getOwnReservations?: boolean;
  lessonName?: string[];
}): Promise<ReservationsResponse | any> => {
  try {
    const queryString = extendedBuildQueryString(data as unknown as Record<string, string | string[]>);

    const response = await getRequest<ReservationsResponse>(`${endpoints.reservations}${queryString}`);

    return response;
  } catch (error: any) {
    return {
      data: error?.data,
      status: error?.status
    };
  }
};

export const getReservation = async (id: string): Promise<GetReservationResponse | any> => {
  try {
    const response = await getRequest<any>(endpoints.reservation(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const createReservation = async (data: CreateReservationRequest): Promise<CreateReservationResponse | any> => {
  try {
    const response = await postRequest<CreateReservationResponse>(endpoints.reservations, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateReservation = async (
  data: UpdateReservationRequest,
  id: string
): Promise<UpdateReservationResponse | any> => {
  try {
    const response = await putRequest<UpdateReservationResponse>(endpoints.reservation(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteReservation = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.reservation(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const joinReservation = async (id: string, data: JoinReservationRequest): Promise<any | any> => {
  try {
    const response = await postRequest<any>(endpoints.joinReservation(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const attendanceReservation = async (id: string, data: AttendanceReservationRequest): Promise<any | any> => {
  try {
    const response = await postRequest<any>(endpoints.attendanceReservation(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const finishReservation = async (id: string): Promise<any | any> => {
  try {
    const response = await putRequest<any>(endpoints.finishReservation(id), { notes: '' });
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelReservationParticipation = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.cancelReservation(id));
    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelReservation = async (id: string, cancelReason: string): Promise<any | any> => {
  try {
    const response = await putRequest<any>(endpoints.cancelReservation(id) + buildQueryString({ cancelReason }));
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeUserFromReservation = async (id: string, studentId: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.removeUserFromReservation(id, studentId));
    return response;
  } catch (error) {
    throw error;
  }
};

export const reservationsLessons = async (): Promise<ReservationLessonsResponse | any> => {
  try {
    const response = await getRequest<ReservationLessonsResponse>(endpoints.reservationsLessons);
    return response;
  } catch (error) {
    return error;
  }
};
