import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { CreateOrEditStudentModalProps, CreateOrEditStudentModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { CreateStudentRequest, UpdateStudentRequest } from 'api/services/students/types';
import { createStudent, updateStudent } from 'api/services/students';
import { HSeparator } from 'components/separator/Separator';
import { Select as MultiSelect } from 'chakra-react-select';
import { formatTimeWithSeconds } from 'helpers/date';

export const dayOptions = [
  { value: 'Monday', label: 'Pazartesi' },
  { value: 'Tuesday', label: 'Salı' },
  { value: 'Wednesday', label: 'Çarşamba' },
  { value: 'Thursday', label: 'Perşembe' },
  { value: 'Friday', label: 'Cuma' },
  { value: 'Saturday', label: 'Cumartesi' },
  { value: 'Sunday', label: 'Pazar' }
];

export default function CreateOrEditStudentModal(props: CreateOrEditStudentModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { student, onSuccessCallback, classesOptions } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayReservationRestriction, setIsDisplayReservationRestriction] = useState(false);

  const handleSubmit = async (
    values: CreateOrEditStudentModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditStudentModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      if (student?.id) {
        const payload: UpdateStudentRequest = {
          userName: student.userName,
          nameSurname: values.nameSurname,
          classId: values.classId,
          email: values.email,
          reservationStartTime: values.reservationStartTime
            ? formatTimeWithSeconds(values.reservationStartTime)
            : undefined,
          reservationEndTime: values.reservationEndTime ? formatTimeWithSeconds(values.reservationEndTime) : undefined,
          availableDays: values.availableDays.length > 0 ? values.availableDays : undefined,
          banDays: values.banDays
        };

        const updateStudentResponse = await updateStudent(payload, student.id.toString());

        if (updateStudentResponse.status === 400) {
          if (Array.isArray(updateStudentResponse.data)) {
            const errorMessages = updateStudentResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(updateStudentResponse.data);
        }

        if (updateStudentResponse.id) {
          snackbar('Başarıyla güncellendi', 'success');
          return onSuccessCallback();
        }

        throw new Error('Bir şeyler hatalı gitti');
      } else {
        const payload: CreateStudentRequest = {
          userName: values.userName,
          nameSurname: values.nameSurname,
          classId: values.classId,
          email: values.email,
          password: values.password,
          reservationStartTime: values.reservationStartTime ? formatTimeWithSeconds(values.reservationStartTime) : null,
          reservationEndTime: values.reservationEndTime ? formatTimeWithSeconds(values.reservationEndTime) : null,
          availableDays: values.availableDays.length > 0 ? values.availableDays : undefined,
          banDays: values.banDays
        };

        const createStudentResponse = await createStudent(payload);

        if (createStudentResponse.status === 400) {
          if (Array.isArray(createStudentResponse.data)) {
            const errorMessages = createStudentResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(createStudentResponse.data);
        }

        if (!createStudentResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla oluşturuldu', 'success');
        onSuccessCallback();
      }
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        userName: student?.userName || '',
        nameSurname: student?.nameSurname,
        classId: student?.classId,
        email: student?.email || '',
        password: student?.password || '',
        reservationStartTime: student?.reservationStartTime || undefined,
        reservationEndTime: student?.reservationEndTime || undefined,
        availableDays: student?.availableDays || [],
        banDays: student?.banDays || undefined
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string(),
        nameSurname: Yup.string().max(255).required('Ad Soyad zorunlu'),
        classId: Yup.number().required('Sınıf zorunlu'),
        email: Yup.string().required('E-Mail zorunlu'),
        password: Yup.string(),
        reservationStartTime: Yup.string().optional(),
        reservationEndTime: Yup.string()
          .optional()
          .test('not-equal', 'Başlangıç ve Bitiş Saati aynı olamaz', function (value) {
            const { reservationStartTime } = this.parent;
            if (reservationStartTime && value) {
              return value !== reservationStartTime;
            }

            return true;
          }),
        availableDays: Yup.array(),
        banDays: Yup.number().optional()
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            {!student?.id && (
              <Field name='userName'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.userName && form.touched.userName)}>
                    <FormLabel>Kullanıcı Adı</FormLabel>
                    <Input {...field} placeholder='Kullanıcı Adı' _dark={{ color: 'white' }} />
                    <FormHelperText>
                      Kullanıcı adınızda Türkçe karakterler (ç, ğ, ı, ö, ş, ü) bulunmamalıdır.
                    </FormHelperText>
                    <FormErrorMessage>{form.errors.userName as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Field name='nameSurname'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.nameSurname && form.touched.nameSurname)}>
                  <FormLabel>Ad Soyad</FormLabel>
                  <Input {...field} placeholder='Ad Soyad' _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.nameSurname as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='classId'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.classId && form.touched.classId)}>
                  <FormLabel>Sınıf</FormLabel>
                  <Select {...field} placeholder='Sınıf' _dark={{ color: 'white' }}>
                    {classesOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.classId as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='email'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.email && form.touched.email)}>
                  <FormLabel>E-Mail Adresi</FormLabel>
                  <Input {...field} placeholder='E-Mail Adresi' _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {!student?.id && (
              <Field name='password'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.password && form.touched.password)}>
                    <FormLabel>Parola</FormLabel>
                    <Input {...field} placeholder='Parola' type='password' _dark={{ color: 'white' }} />
                    <FormHelperText>
                      Şifrenizde en az bir büyük harf, bir rakam ve bir noktalama işareti bulunmalıdır.
                    </FormHelperText>
                    <FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Checkbox
              isChecked={isDisplayReservationRestriction}
              onChange={(e) => setIsDisplayReservationRestriction(e.target.checked)}
            >
              Rezervasyon Kısıtlamalarını Göster
            </Checkbox>

            {isDisplayReservationRestriction && (
              <>
                <HSeparator mt='5px' mb='5px' />

                <Text fontSize='md' fontWeight='bold'>
                  Rezervasyon Kısıtlamaları
                </Text>

                <Field name='availableDays'>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={Boolean(form.errors.availableDays && form.touched.availableDays)}>
                      <FormLabel>Günler</FormLabel>
                      <MultiSelect
                        isMulti
                        name='colors'
                        options={dayOptions}
                        placeholder='Günler'
                        closeMenuOnSelect={false}
                        value={dayOptions.filter((option) => field.value.includes(option.value))}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map((option) => option.value);
                          form.setFieldValue(field.name, selectedValues);
                        }}
                      />
                      <FormErrorMessage>{form.errors.availableDays as string}</FormErrorMessage>
                      <FormHelperText>Öğrencinin rezervasyon alabileceği günleri seçiniz.</FormHelperText>
                    </FormControl>
                  )}
                </Field>

                <SimpleGrid columns={2} spacing={5}>
                  <Field name='reservationStartTime'>
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={Boolean(form.errors.reservationStartTime && form.touched.reservationStartTime)}
                      >
                        <FormLabel>Başlangıç Saati</FormLabel>
                        <Input {...field} type='time' _dark={{ color: 'white' }} />
                        <FormErrorMessage>{form.errors.reservationStartTime as string}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name='reservationEndTime'>
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        isInvalid={Boolean(form.errors.reservationEndTime && form.touched.reservationEndTime)}
                      >
                        <FormLabel>Bitiş Saati</FormLabel>
                        <Input {...field} type='time' _dark={{ color: 'white' }} />
                        <FormErrorMessage>{form.errors.reservationEndTime as string}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </SimpleGrid>

                <Field name='banDays'>
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={Boolean(form.errors.banDays && form.touched.banDays)}>
                      <FormLabel>Kısıtlama Gün Sayısı</FormLabel>
                      <Input {...field} type='number' _dark={{ color: 'white' }} />
                      <FormErrorMessage>{form.errors.banDays as string}</FormErrorMessage>
                      <FormHelperText>
                        Öğrenci rezervasyona katılmadığında otomatik olarak kısıtlama alacağı gün sayısını belirleyiniz.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>

                <Button
                  onClick={() => {
                    setFieldValue('reservationStartTime', '');
                    setFieldValue('reservationEndTime', '');
                  }}
                >
                  Saatleri Sıfırla
                </Button>
              </>
            )}
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
