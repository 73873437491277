import { Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, useColorMode } from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { JoinReservationModalProps, JoinReservationModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { joinReservation } from 'api/services/reservations';
import { Select as MultiSelect } from 'chakra-react-select';

export default function JoinReservationModal(props: JoinReservationModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { reservationId, lessonsSubjects, onSuccessCallback } = props;
  const { colorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: JoinReservationModalSubmit,
    { setSubmitting }: FormikHelpers<JoinReservationModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      const joinReservationResponse = await joinReservation(reservationId.toString(), {
        lessonSubjectIds: values.lessonSubjectIds
      });

      if (joinReservationResponse.status === 400) {
        if (Array.isArray(joinReservationResponse.data)) {
          const errorMessages = joinReservationResponse.data.map((error: any) => error.description).join(' ');
          throw new Error(errorMessages);
        }

        throw new Error(joinReservationResponse.data);
      }

      if (joinReservationResponse.id) {
        snackbar('Rezervasyon başarıyla oluşturuldu', 'success');
        return onSuccessCallback();
      }

      throw new Error('Bir şeyler hatalı gitti');
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        lessonSubjectIds: undefined
      }}
      validationSchema={Yup.object().shape({
        lessonSubjectIds: Yup.array().required('Kazanım seçimi zorunlu')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            <Field name='lessonSubjectIds'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.lessonSubjectIds && form.touched.lessonSubjectIds)}>
                  <FormLabel>Kazanımlar</FormLabel>
                  <MultiSelect
                    isMulti
                    name='lessonSubjectIds'
                    options={lessonsSubjects}
                    placeholder='Kazanımler'
                    closeMenuOnSelect={false}
                    onChange={(selectedOptions) => {
                      const selectedValues = selectedOptions.map((option) => option.value);
                      form.setFieldValue(field.name, selectedValues);
                    }}
                    chakraStyles={{
                      input: (provided) => ({
                        ...provided,
                        color: colorMode === 'dark' ? 'white' : 'black'
                      })
                    }}
                  />
                  <FormErrorMessage>{form.errors.lessonSubjectIds as string}</FormErrorMessage>
                  <FormHelperText>Kazanımları eksiksiz seçtiğinden emin ol.</FormHelperText>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Onayla
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
