import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { deleteStudent, getStudent } from 'api/services/students';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useEffect, useState } from 'react';
import { StudentsRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { getStudents } from 'api/services/students';
import CreateOrEditStudentModal from 'components/students/create-or-edit-student-modal';
import { getClasses } from 'api/services/classes';
import { ClassesResponse } from 'api/services/classes/types';
import { GetStudentResponse, StudentsResponse } from 'api/services/students/types';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';

const columnsDataCheck: Column<StudentsRowObj>[] = [
  { name: 'nameSurname', displayName: 'Adı Soyadı' },
  { name: 'userName', displayName: 'Kullanıcı Adı' },
  { name: 'email', displayName: 'E-Mail Adresi' },
  { name: 'className', displayName: 'Sınıfı' }
];

export default function Students() {
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState<StudentsResponse>({ totalCount: 0, students: [] });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchStudents = async (page?: number, pageSize?: number) => {
    try {
      const studentsResponse = await getStudents({ page: page?.toString(), pageSize: pageSize?.toString() });

      setStudents(studentsResponse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchStudents(page, pageSize);
  };

  useEffect(() => {
    fetchStudents(page, pageSize);
  }, []);

  const handleClickCreateStudent = async () => {
    const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

    const mappedClasses = classesResponse.classes.map((item) => ({
      id: item.id,
      label: `${item.number}/${item.branchName}`,
      value: item.id.toString()
    }));

    showModal(
      <CreateOrEditStudentModal classesOptions={mappedClasses} onSuccessCallback={handleOnSuccessCallback} />,
      'Öğrenci Oluştur'
    );
  };

  const handleEdit = async (id: number) => {
    try {
      const studentResponse: GetStudentResponse = await getStudent(id.toString());
      const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

      const mappedClasses = classesResponse.classes.map((item) => ({
        id: item.id,
        label: `${item.number}/${item.branchName}`,
        value: item.id.toString()
      }));

      showModal(
        <CreateOrEditStudentModal
          student={studentResponse}
          classesOptions={mappedClasses}
          onSuccessCallback={handleOnSuccessCallback}
        />,
        'Öğrenci Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteStudent(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await fetchStudents(page, pageSize);
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchStudents(pageIndex + 1, pageSize);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
            onClick={handleClickCreateStudent}
          >
            Yeni Ekle
          </ControlledButton>
        </Flex>
        <DataTable
          tableData={students.students}
          columns={columnsDataCheck}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
          loading={loading}
          totalCount={students.totalCount}
          onPaginationChangeCallback={onPaginationChange}
          displayProfileButton
        />
      </SimpleGrid>
    </Box>
  );
}
