import { createHttpRequests } from 'api/requests';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  DashboardResponse,
  LoginRequest,
  LoginResponse,
  MeResponse,
  ProfileAnalysisResponse
} from './types';

const { postRequest, getRequest } = createHttpRequests(axiosServices);

export const login = async (loginRequest: LoginRequest): Promise<LoginResponse | any> => {
  try {
    const response = await postRequest<LoginResponse>(endpoints.login, loginRequest);
    return response;
  } catch (error) {
    return error;
  }
};

export const me = async (): Promise<MeResponse | any> => {
  try {
    const response = await getRequest<MeResponse>(endpoints.me);
    return response;
  } catch (error) {
    return error;
  }
};

export const profile = async (id?: string): Promise<ProfileAnalysisResponse | any> => {
  try {
    const url = id ? `${endpoints.profile}/${id}` : `${endpoints.profile}/0`;

    const response = await getRequest<ProfileAnalysisResponse>(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const dashboard = async (): Promise<DashboardResponse | any> => {
  try {
    const response = await getRequest<DashboardResponse>(endpoints.info);
    return response;
  } catch (error) {
    return error;
  }
};

export const changePassword = async (
  changePasswordPayload: ChangePasswordRequest,
  isFromAdmin: boolean = false
): Promise<ChangePasswordResponse | any> => {
  try {
    const response = await postRequest<ChangePasswordResponse>(
      isFromAdmin ? endpoints.hardChangePassword : endpoints.changePassword,
      changePasswordPayload
    );
    return response;
  } catch (error) {
    debugger;
    return error;
  }
};
