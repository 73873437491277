import { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { ApiInterceptorConfig } from './interface';
import { eraseCookie, getCookie } from 'helpers/cookie-manager';

const requestInterceptor = async (config: ApiInterceptorConfig): Promise<ApiInterceptorConfig> => {
  const accessToken = getCookie('accessToken');

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
};

const responseInterceptor = async (response: AxiosResponse): Promise<AxiosResponse> => {
  return response;
};

const errorInterceptor = async (error: AxiosError) => {
  if (error?.response?.status === HttpStatusCode.Unauthorized) {
    eraseCookie('accessToken');
    window.location.href = '/auth/sign-in';
  }
  return Promise.reject(error);
};

export { requestInterceptor, responseInterceptor, errorInterceptor };
