export const getLessonColor = (lessons: any) => {
  const lessonColors = [
    { lessonName: 'Türkçe', color: '#FF6384' },
    { lessonName: 'Matematik', color: '#36A2EB' },
    { lessonName: 'Geometri', color: '#FFCE56' },
    { lessonName: 'Fizik', color: '#4BC0C0' },
    { lessonName: 'Biyoloji', color: '#9966FF' },
    { lessonName: 'Kimya', color: '#FF9F40' },
    { lessonName: 'Tarih', color: '#FF6384' },
    { lessonName: 'Coğrafya', color: '#36A2EB' },
    { lessonName: 'Din Kültütü', color: '#FFCE56' },
    { lessonName: 'Felsefe', color: '#4BC0C0' },
    { lessonName: 'Edebiyat', color: '#9966FF' },
    { lessonName: 'Mantık', color: '#FF9F40' },
    { lessonName: 'Psikoloji', color: '#FF6384' }
  ];

  return lessons.map((lesson: any) => {
    const found = lessonColors.find((colorItem) => colorItem.lessonName === lesson.lessonName);
    return found ? found.color : '#000000';
  });
};
