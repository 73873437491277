import { createHttpRequests } from 'api/requests';
import {
  SalonsResponse,
  CreateSalonRequest,
  CreateSalonResponse,
  UpdateSalonRequest,
  UpdateSalonResponse,
  GetSalonResponse
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getSalons = async (data?: { page: string; pageSize: string }): Promise<SalonsResponse | any> => {
  try {
    const response = await getRequest<SalonsResponse>(endpoints.salons, {
      page: data.page || '1',
      pageSize: data.pageSize || '10'
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSalon = async (id: string): Promise<GetSalonResponse | any> => {
  try {
    const response = await getRequest<any>(endpoints.salon(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const createSalon = async (data: CreateSalonRequest): Promise<CreateSalonResponse | any> => {
  try {
    const response = await postRequest<CreateSalonResponse>(endpoints.salons, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateSalon = async (data: UpdateSalonRequest, id: string): Promise<UpdateSalonResponse | any> => {
  try {
    const response = await putRequest<UpdateSalonResponse>(endpoints.salon(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteSalon = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.salon(id));
    return response;
  } catch (error) {
    return error;
  }
};
