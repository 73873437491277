import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { BRANCHS, CLASSES, CreateOrEditClassModalProps, CreateOrEditClassModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { CreateClassRequest, UpdateClassRequest } from 'api/services/classes/types';
import { createClass, updateClass } from 'api/services/classes';

const classOptions = CLASSES.map((item) => ({
  id: item,
  label: item,
  value: item.toString()
}));

const branchOptions = BRANCHS.map((item) => ({
  id: item,
  label: item,
  value: item.toString()
}));

export default function CreateOrEditClassModal(props: CreateOrEditClassModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { classData, onSuccessCallback } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: CreateOrEditClassModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditClassModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      if (classData?.id) {
        const payload: UpdateClassRequest = {
          branchName: values.branchName,
          number: values.number,
          capacity: values.capacity
        };

        const updateClassResponse = await updateClass(payload, classData?.id.toString());

        if (updateClassResponse.status === 400) {
          if (Array.isArray(updateClassResponse.data)) {
            const errorMessages = updateClassResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(updateClassResponse.data);
        }

        if (updateClassResponse.id) {
          snackbar('Başarıyla güncellendi', 'success');
          return onSuccessCallback();
        }

        throw new Error('Bir şeyler hatalı gitti');
      } else {
        const payload: CreateClassRequest = {
          branchName: values.branchName,
          number: values.number,
          capacity: values.capacity
        };

        const createClassResponse = await createClass(payload);

        if (createClassResponse.status === 400) {
          if (Array.isArray(createClassResponse.data)) {
            const errorMessages = createClassResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(createClassResponse.data);
        }

        if (createClassResponse.id) {
          snackbar('Başarıyla oluşturuldu', 'success');
          return onSuccessCallback();
        }

        throw new Error('Bir şeyler hatalı gitti');
      }
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik
      initialValues={{
        branchName: classData?.branchName || '',
        capacity: classData?.capacity,
        number: classData?.number || ''
      }}
      validationSchema={Yup.object().shape({
        branchName: Yup.string().max(255).required('Şube zorunlu'),
        number: Yup.string().max(255).required('Sınıf zorunlu'),
        capacity: Yup.number().required('Kapasite zorunlu')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Field name='number'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={Boolean(form.errors.number && form.touched.number)}>
                <FormLabel>Sınıf</FormLabel>
                <Select {...field} _dark={{ color: 'white' }} placeholder='Sınıf'>
                  {classOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {typeof form.errors.number === 'string' ? form.errors.number : null}{' '}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name='branchName'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={Boolean(form.errors.branchName && form.touched.branchName)}>
                <FormLabel>Şube</FormLabel>
                <Select _dark={{ color: 'white' }} {...field} placeholder='Sınıf'>
                  {branchOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{form.errors.branchName as string}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name='capacity'>
            {({ field, form }: FieldProps) => (
              <FormControl isInvalid={Boolean(form.errors.capacity && form.touched.capacity)}>
                <FormLabel>Kapasite</FormLabel>
                <Input {...field} placeholder='Örn: 30' type='number' _dark={{ color: 'white' }} />
                <FormErrorMessage>
                  {typeof form.errors.capacity === 'string' ? form.errors.capacity : null}{' '}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
