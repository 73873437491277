import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import sessionSlice from 'lib/slices/session/session-slice';
import notificationsSlice from './slices/notifications/notifications-slice';

const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: {
    session: sessionSlice,
    notifications: notificationsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(listenerMiddleware.middleware)
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
