import { createHttpRequests } from 'api/requests';
import {
  LessonsResponse,
  CreateLessonRequest,
  CreateLessonResponse,
  UpdateLessonRequest,
  UpdateLessonResponse
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getLessons = async (): Promise<LessonsResponse | any> => {
  try {
    const response = await getRequest<LessonsResponse>(endpoints.lessons);
    return response;
  } catch (error) {
    return error;
  }
};

export const createLesson = async (data: CreateLessonRequest): Promise<CreateLessonResponse | any> => {
  try {
    const response = await postRequest<CreateLessonResponse>(endpoints.lessons, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateLesson = async (data: UpdateLessonRequest, id: string): Promise<UpdateLessonResponse | any> => {
  try {
    const response = await putRequest<UpdateLessonResponse>(endpoints.lesson(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteLesson = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.lesson(id));
    return response;
  } catch (error) {
    return error;
  }
};
