import axios, { AxiosInstance } from 'axios';
import { requestInterceptor, responseInterceptor, errorInterceptor } from './interceptors';

const axiosServices: AxiosInstance = axios.create({
  baseURL: 'https://api.edumiu.com/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
});

axiosServices.interceptors.request.use(requestInterceptor, errorInterceptor);

axiosServices.interceptors.response.use(responseInterceptor, errorInterceptor);

export default axiosServices;
