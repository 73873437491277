import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { ControlledButtonProps } from './types';
import { useAppSelector } from 'lib/hooks';

const ControlledButton: FC<ControlledButtonProps> = ({ module, roles, ...buttonProps }) => {
  const organizationModules = useAppSelector((selector) => selector.session.modules);
  const userRole = useAppSelector((selector) => selector.session.role);

  if ((module && !organizationModules.includes(module)) || (Array.isArray(roles) && !roles.includes(userRole))) {
    return null;
  }

  return <Button {...buttonProps} />;
};

export default ControlledButton;
