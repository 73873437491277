import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import PanelLayout from './layouts/panel';
import {
  ChakraProvider
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import PrivateRoute from 'routes/PrivateRoute';
import { useAppSelector } from 'lib/hooks';
import GuestRoute from 'routes/GuestRoute';
// Chakra imports

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  const isLoading = useAppSelector((selector) => selector.session.isLoading);

  return !isLoading ? (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route
          path='auth/*'
          element={
            <GuestRoute>
              <AuthLayout />
            </GuestRoute>
          }
        />
        <Route path='admin/*' element={<PanelLayout theme={currentTheme} setTheme={setCurrentTheme} />} />
        <Route
          path='panel/*'
          element={
            <PrivateRoute>
              <PanelLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </PrivateRoute>
          }
        />
        <Route path='panel/*' element={<Navigate to='/panel/home' replace />} />
        <Route path='/' element={<Navigate to='/panel/home' replace />} />
        <Route path='*' element={<Navigate to='/panel/home' replace />} />
      </Routes>
    </ChakraProvider>
  ) : (
    <></>
  );
}
