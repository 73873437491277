// Chakra imports
import { Flex, Text, Image } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import { useAppSelector } from 'lib/hooks';

export function SidebarBrand() {
  const organization = useAppSelector((selector) => selector.session.organization);

  return (
    <Flex alignItems='center' flexDirection='column'>
      <Image borderRadius='full' width='auto' height={120} mb={0} src={organization.logoUrl} alt='Logo' />

      <Text fontSize='2xl' fontWeight='bold' mt={1}>
        {organization.name}
      </Text>
      <HSeparator mb='20px' mt='20px' />
    </Flex>
  );
}

export default SidebarBrand;
