const endpoints = {
  login: '/users/token',
  changePassword: '/users/change-password',
  hardChangePassword: '/info/admin/change-password',
  me: '/info/me',
  info: '/info',
  profile: '/info/profile',
  lessons: '/lessons',
  lesson: (id: string) => `/lessons/${id}`,
  students: '/students',
  student: (id: string) => `/students/${id}`,
  classes: '/classes',
  class: (id: string) => `/classes/${id}`,
  teachers: '/teachers',
  teacher: (id: string) => `/teachers/${id}`,
  salons: '/salons',
  salon: (id: string) => `/salons/${id}`,
  reservations: '/reservations',
  reservation: (id: string) => `/reservations/${id}`,
  joinReservation: (id: string) => `/reservations/${id}/join`,
  cancelReservation: (id: string) => `/reservations/${id}/cancel`,
  lessonsSubjects: '/lessons/subjects',
  attendanceReservation: (id: string) => `/reservations/${id}/attendance`,
  finishReservation: (id: string) => `/reservations/${id}/finish`,
  removeUserFromReservation: (id: string, studentId: string) => `/reservations/${id}/${studentId}`,
  reservationsLessons: '/reservations/lesson',
  reservationRestriction: (id: string) => `/students/class/${id}/reservation`,
  notifications: '/notifications',
  readNotifications: '/notifications/read'
};

export default endpoints;
