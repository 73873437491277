import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';

type RowObj = {
  name: string;
  officerName: string;
  address: string;
  phoneNumber: string;
};

const tableData: RowObj[] = [
  {
    name: 'İstanbul Anadolu Şubesi',
    officerName: 'Ahmet Yılmaz',
    address: 'Bağdat Caddesi No:45 Kadıköy, İstanbul',
    phoneNumber: '0216 123 45 67'
  },
  {
    name: 'Ankara Merkez Şubesi',
    officerName: 'Elif Kaya',
    address: 'Atatürk Bulvarı No:89 Çankaya, Ankara',
    phoneNumber: '0312 765 43 21'
  },
  {
    name: 'İzmir Karşıyaka Şubesi',
    officerName: 'Mehmet Çetin',
    address: 'Mustafa Kemal Caddesi No:12 Karşıyaka, İzmir',
    phoneNumber: '0232 456 78 90'
  },
  {
    name: 'Bursa Nilüfer Şubesi',
    officerName: 'Hülya Demir',
    address: 'Fethiye Mahallesi No:67 Nilüfer, Bursa',
    phoneNumber: '0224 345 67 89'
  }
];

const columnsDataCheck: Column<RowObj>[] = [
  { name: 'name', displayName: 'Şube Adı' },
  { name: 'officerName', displayName: 'Yetkili Adı' },
  { name: 'address', displayName: 'Adresi' },
  { name: 'phoneNumber', displayName: 'Telefon Numarası' }
];

export default function LessonSubjects() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
          >
            Yeni Ekle
          </ControlledButton>
        </Flex>
        <DataTable tableData={tableData} columns={columnsDataCheck} />
      </SimpleGrid>
    </Box>
  );
}
