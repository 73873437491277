import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { ChangePasswordRequest } from 'api/services/auth/types';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { changePassword } from 'api/services/auth';
import { ChangePasswordModalSubmit } from './types';
import { useAppSelector } from 'lib/hooks';

export default function ChangePasswordModal({ userName }: { userName: string }) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const username = useAppSelector((selector) => selector.session?.username);

  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewAgainPassword, setShowNewAgainPassword] = useState(false);

  const handleSubmit = async (
    values: ChangePasswordModalSubmit,
    { setSubmitting }: FormikHelpers<ChangePasswordModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      const payload: ChangePasswordRequest = {
        username: userName || username,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      };

      if (userName) {
        delete payload.oldPassword;
      }

      const changePasswordResponse = await changePassword(payload, userName ? true : false);

      if (changePasswordResponse.status === 400) {
        throw new Error(changePasswordResponse.data);
      }

      snackbar('Şifre başarıyla güncellendi', 'success');
      hideModal();
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleClick = (value: string) => {
    switch (value) {
      case 'old':
        setShowOldPassword(!showOldPassword);
        break;
      case 'new':
        setShowNewPassword(!showNewPassword);
        break;
      case 'newAgain':
        setShowNewAgainPassword(!showNewAgainPassword);
        break;

      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        newPasswordAgain: ''
      }}
      validationSchema={Yup.object({
        oldPassword: userName ? Yup.string() : Yup.string().required('Eski şifre zorunludur.'),
        newPassword: Yup.string()
          .required('Yeni şifre zorunludur.')
          .min(6, 'Şifre en az 6 karakter olmalıdır.')
          .matches(/[A-Z]/, 'Şifre en az bir büyük harf içermelidir.')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Şifre en az bir noktalama işareti içermelidir.'),
        newPasswordAgain: Yup.string()
          .required('Yeni şifre tekrar zorunludur.')
          .oneOf([Yup.ref('newPassword')], 'Şifreler aynı olmalıdır.')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ values, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            {!userName && (
              <Field name='oldPassword'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.oldPassword && form.touched.oldPassword)}>
                    <FormLabel>Eski Şifre</FormLabel>
                    <InputGroup>
                      <Input
                        {...field}
                        type={showOldPassword ? 'text' : 'password'}
                        placeholder='Eski Şifre'
                        _dark={{ color: 'white' }}
                      />
                      <InputRightElement display='flex' alignItems='center'>
                        <Icon
                          color={'gray.400'}
                          _hover={{ cursor: 'pointer' }}
                          as={showOldPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={() => handleClick('old')}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors.oldPassword as string}</FormErrorMessage>
                    <FormHelperText>Şifrenizi unuttuysanız idare ile iletişime geçmelisiniz.</FormHelperText>
                  </FormControl>
                )}
              </Field>
            )}
            <Field name='newPassword'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.newPassword && form.touched.newPassword)}>
                  <FormLabel>Yeni Şifre</FormLabel>
                  <InputGroup>
                    <Input
                      {...field}
                      type={showNewPassword ? 'text' : 'password'}
                      placeholder='Yeni Şifre'
                      _dark={{ color: 'white' }}
                    />
                    <InputRightElement display='flex' alignItems='center'>
                      <Icon
                        color={'gray.400'}
                        _hover={{ cursor: 'pointer' }}
                        as={showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={() => handleClick('new')}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.newPassword as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name='newPasswordAgain'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.newPasswordAgain && form.touched.newPasswordAgain)}>
                  <FormLabel>Yeni Şifre Tekrar</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      {...field}
                      type={showNewAgainPassword ? 'text' : 'password'}
                      placeholder='Yeni Şifre Tekrar'
                      _dark={{ color: 'white' }}
                    />
                    <InputRightElement display='flex' alignItems='center'>
                      <Icon
                        color={'gray.400'}
                        _hover={{ cursor: 'pointer' }}
                        as={showNewAgainPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={() => handleClick('newAgain')}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.newPasswordAgain as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Onayla
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
