import { Class } from 'api/services/classes/types';

export interface CreateOrEditClassModalProps {
  classData?: Class;
  onSuccessCallback: () => void;
}

export interface CreateOrEditClassModalSubmit {
  branchName: string;
  number: string;
  capacity: number;
}

export const CLASSES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'Mezun'];

export const BRANCHS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'İ',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'Y',
  'Z',
  'SAY1',
  'SAY2',
  'SAY3',
  'SAY4',
  'SAY5',
  'TM1',
  'TM2',
  'TM3',
  'TM4',
  'TM5',
  'SOZEL1',
  'SOZEL2'
];
