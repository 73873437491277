import { Box, Icon, SimpleGrid } from '@chakra-ui/react';
import { dashboard } from 'api/services/auth';
import { DashboardResponse } from 'api/services/auth/types';
import IconBox from 'components/icons/IconBox';
import ControlledMiniStatictics from 'components/with-controlled/mini-statictics';
import { USER_ROLES } from 'constants/users';
import { useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaChalkboardTeacher,
  FaClipboardList,
  FaSchool,
  FaUserClock,
  FaUserGraduate,
  FaUserTie
} from 'react-icons/fa';

export default function Home() {
  const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(null);

  const fetchDashboardData = async () => {
    const dashboardResponse = await dashboard();

    setDashboardData(dashboardResponse);
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ sm: 1, md: 2 }} mt={10} spacing={8}>
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaUserGraduate} color='white' />}
            />
          }
          name='Öğrenciler'
          value={dashboardData?.totalStudents}
          roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaChalkboardTeacher} color='white' />}
            />
          }
          name='Öğretmenler'
          value={dashboardData?.totalTeachers}
          roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaCalendarAlt} color='white' />}
            />
          }
          name='Aktif Rezervasyonlar'
          value={dashboardData?.totalActiveReservations}
          roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN, USER_ROLES.STUDENT, USER_ROLES.TEACHER]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaCalendarCheck} color='white' />}
            />
          }
          name='Tüm Rezervasyonlar'
          value={dashboardData?.totalReservations}
          roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaSchool} color='white' />}
            />
          }
          name='Sınıflar'
          value={dashboardData?.totalClasses}
          roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaUserTie} color='white' />}
            />
          }
          name='Adminler'
          value={dashboardData?.adminsCount}
          roles={[USER_ROLES.SUPER_ADMIN]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaUserClock} color='white' />}
            />
          }
          name='Aktif Rezervasyonlarım'
          value={dashboardData?.myActiveReservationsCount}
          roles={[USER_ROLES.STUDENT, USER_ROLES.TEACHER]}
        />
        <ControlledMiniStatictics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={FaClipboardList} color='white' />}
            />
          }
          name='Tüm Rezervasyonlarım'
          value={dashboardData?.myAllReservationsCount}
          roles={[USER_ROLES.STUDENT, USER_ROLES.TEACHER]}
        />
      </SimpleGrid>
    </Box>
  );
}
