export const buildQueryString = (params: Record<string, string>) => {
  const query = [];

  for (const key in params) {
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
  }

  return query.length ? `?${query.join('&')}` : '';
};

export const locationParser = (val: string) => {
  const searchParams = new URLSearchParams(window.location.href.split('/').pop());
  return searchParams.get(val);
};

export const extendedBuildQueryString = (params: Record<string, string | string[]>) => {
  const query = [];

  for (const key in params) {
    if (Array.isArray(params[key])) {
      (params[key] as string[]).forEach((value) => {
        query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
    } else {
      query.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key] as string)}`);
    }
  }

  return query.length ? `?${query.join('&')}` : '';
};
