// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useAppSelector } from 'lib/hooks';
import { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const userRole = useAppSelector((selector) => selector.session.role);
  const location = useLocation();
  const { ...rest } = props;

  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };

  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Default Brand Text';

    for (let i = 0; i < routes.length; i++) {
      const currentPath = routes[i].layout + routes[i].path;

      if (location.pathname.startsWith(routes[i].layout) && currentPath.includes('/:')) {
        const basePath = currentPath.split('/:')[0];
        if (location.pathname.startsWith(basePath)) {
          return routes[i].name;
        }
      } else if (location.pathname === currentPath) {
        return routes[i].name;
      }
    }

    return activeRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;

    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.startsWith(routes[i].layout + routes[i].path)) {
        return routes[i].secondary;
      }
    }

    return activeNavbar;
  };

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar: string | boolean = false;

    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.startsWith(routes[i].layout + routes[i].path)) {
        return routes[i].name;
      }
    }

    return activeNavbar;
  };

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === '/panel') {
        if (route.withPermission) {
          if (Array.isArray(route.withPermission.roles) && !route.withPermission.roles.includes(userRole)) {
            return <Route path={route.path} element={<Navigate to='/panel/home' replace />} key={key} />;
          }

          if (route.withPermission.module && route.withPermission.module !== 'customModule1') {
            return <Route path={route.path} element={<Navigate to='/panel/home' replace />} key={key} />;
          }
        }

        return <Route path={`${route.path}`} element={route.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar
        }}
      >
        <Sidebar routes={routes} display='none' {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={'Horizon UI Dashboard PRO'}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
              <Routes>
                {getRoutes(routes)}
                <Route path='/' element={<Navigate to='/panel/default' replace />} />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
