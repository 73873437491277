import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { login, me } from 'api/services/auth';
import { setCookie } from 'helpers/cookie-manager';
import { useAppDispatch } from 'lib/hooks';
import { setSession } from 'lib/slices/session/session-slice';
import { useCustomSnackbar } from 'utils/custom-snackbar';

function SignIn() {
  const dispatch = useAppDispatch();
  const snackbar = useCustomSnackbar();

  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleClick = () => setShow(!show);

  const handleClickSignIn = async () => {
    try {
      const payload = {
        username,
        password
      };

      const loginResponse = await login(payload);

      if (loginResponse.status === 400) {
        throw loginResponse.data;
      }

      setCookie('accessToken', loginResponse.token);

      const meResponse = await me();

      dispatch(
        setSession({
          isAuthenticated: true,
          role: 'superadmin',
          email: meResponse.email,
          username: meResponse.username,
          organization: meResponse.organization,
          modules: meResponse.modules,
          nameSurname: meResponse.nameSurname
        })
      );

      if (meResponse) {
        window.location.href = '/panel/home';
      }
    } catch (error: any) {
      snackbar(error, 'error');
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w='100%'
        mx={{ base: 'auto', lg: '0px' }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection='column'
      >
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Giriş Yap
          </Heading>
          <Text mb='36px' ms='4px' color={textColorSecondary} fontWeight='400' fontSize='md'>
            Kullanıcı adı ve şifreni yazarak hemen sistemi kullanmaya başla!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button> */}
          {/* <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
              Kullanıcı Adı&nbsp;<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: '0px', md: '0px' }}
              type='text'
              placeholder='Kullanıcı Adı'
              value={username}
              onChange={(event) => {
                setUsername(event?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleClickSignIn();
                }
              }}
              mb='24px'
              fontWeight='500'
              size='lg'
            />
            <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
              Şifre&nbsp;<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Şifre'
                mb='24px'
                size='lg'
                value={password}
                onChange={(event) => {
                  setPassword(event?.target?.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleClickSignIn();
                  }
                }}
                type={show ? 'text' : 'password'}
                variant='auth'
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox id='remember-login' colorScheme='brandScheme' me='10px' />
                <FormLabel htmlFor='remember-login' mb='0' fontWeight='normal' color={textColor} fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text color={textColorBrand} fontSize='sm' w='124px' fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleClickSignIn}
            >
              Giriş Yap
            </Button>
          </FormControl>
          {/* <Flex flexDirection='column' justifyContent='center' alignItems='start' maxW='100%' mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text color={textColorBrand} as='span' ms='5px' fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
