// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { StudentBannerProps } from './types';
import banner from 'assets/img/auth/banner.png';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import ChangePasswordModal from 'components/profile/change-password-modal';

export default function StudentBanner(props: StudentBannerProps) {
  const { myJoinReservationsCount, myActiveReservationsCount, avatar, name, className, ...rest } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue('white !important', '#111C44 !important');
  const { showModal } = useUI();

  const handleClickChangePassword = async () => {
    showModal(<ChangePasswordModal userName={props.userName || ''} />, 'Şifre Değiştir');
  };

  return (
    <Card mb={{ base: '0px', lg: '20px' }} alignItems='center' {...rest}>
      <Box bg={`url(${banner})`} bgSize='cover' borderRadius='16px' h='81px' w='100%' />
      <Flex width='100%' alignItems='center' flexDirection={{ sm: 'column', md: 'row' }}>
        <Avatar mx='40px' src={avatar} h='125px' w='125px' mt='-43px' border='4px solid' borderColor={borderColor} />

        <Flex alignItems='center' flexDirection='column' whiteSpace='nowrap'>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
            {name}
          </Text>
          <Text color={textColorSecondary} fontSize='sm'>
            {className}
          </Text>
        </Flex>
        <Flex
          w='100%'
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems={'center'}
        >
          <Box display={{ base: 'none', md: 'block' }}></Box>
          <Flex flexDirection='row' gap={20}>
            <Flex alignItems='center' flexDirection='column' textAlign='center'>
              <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                {myActiveReservationsCount}
              </Text>
              <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                Aldığı Rezervasyonlar
              </Text>
            </Flex>
            <Flex alignItems='center' flexDirection='column' textAlign='center'>
              <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
                {myJoinReservationsCount}
              </Text>
              <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                Katıldığı Rezervasyonlar
              </Text>
            </Flex>
          </Flex>
          <Box w={{ base: '100%', md: 'auto' }} mt={{ base: '15px', md: '0px' }}>
            <ControlledButton
              variant='outline'
              w={{ base: '100%', md: 'auto' }}
              colorScheme='facebook'
              roles={[USER_ROLES.STUDENT, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
              size={'md'}
              onClick={handleClickChangePassword}
            >
              Şifreni Değiştir
            </ControlledButton>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}
