import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  useColorMode
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { CreateOrEditTeacherModalProps, CreateOrEditTeacherModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { CreateTeacherRequest, UpdateTeacherRequest } from 'api/services/teachers/types';
import { createTeacher, updateTeacher } from 'api/services/teachers';

export default function CreateOrEditTeacherModal(props: CreateOrEditTeacherModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { colorMode } = useColorMode();
  const { teacher, onSuccessCallback, lessonsOptions } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: CreateOrEditTeacherModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditTeacherModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      if (teacher?.id) {
        const payload: UpdateTeacherRequest = {
          userName: values.userName,
          nameSurname: values.nameSurname,
          majorId: values.majorId,
          email: values.email,
          password: values.password
        };

        const updateTeacherResponse = await updateTeacher(payload, teacher.id.toString());

        if (updateTeacherResponse.status === 400) {
          if (Array.isArray(updateTeacherResponse.data)) {
            const errorMessages = updateTeacherResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(updateTeacherResponse.data);
        }

        if (!updateTeacherResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla güncellendi', 'success');
        onSuccessCallback();
      } else {
        const payload: CreateTeacherRequest = {
          userName: values.userName,
          nameSurname: values.nameSurname,
          majorId: values.majorId,
          email: values.email,
          password: values.password
        };

        const createTeacherResponse = await createTeacher(payload);

        if (createTeacherResponse.status === 400) {
          if (Array.isArray(createTeacherResponse.data)) {
            const errorMessages = createTeacherResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(createTeacherResponse.data);
        }

        if (!createTeacherResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla oluşturuldu', 'success');
        onSuccessCallback();
        hideModal();
      }
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        userName: teacher?.userName || '',
        nameSurname: teacher?.nameSurname,
        majorId: teacher?.majorId,
        email: teacher?.email || '',
        password: teacher?.password || ''
      }}
      validationSchema={Yup.object().shape({
        userName: Yup.string().max(255),
        nameSurname: Yup.string().max(255).required('Ad Soyad zorunlu'),
        majorId: Yup.number().required('Sınıf zorunlu'),
        email: Yup.string().required('E-Mail zorunlu'),
        password: Yup.string()
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            {!teacher?.id && (
              <Field name='userName'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.userName && form.touched.userName)}>
                    <FormLabel>Kullanıcı Adı</FormLabel>
                    <Input {...field} placeholder='Kullanıcı Adı' _dark={{ color: 'white' }} />
                    <FormHelperText>
                      Kullanıcı adınızda Türkçe karakterler (ç, ğ, ı, ö, ş, ü) bulunmamalıdır.
                    </FormHelperText>
                    <FormErrorMessage>{form.errors.userName as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Field name='nameSurname'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.nameSurname && form.touched.nameSurname)}>
                  <FormLabel>Ad Soyad</FormLabel>
                  <Input {...field} placeholder='Ad Soyad' _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.nameSurname as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='majorId'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.majorId && form.touched.majorId)}>
                  <FormLabel>Branş</FormLabel>
                  <Select _dark={{ color: 'white' }} {...field} placeholder='Branş'>
                    {lessonsOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.majorId as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='email'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.email && form.touched.email)}>
                  <FormLabel>E-Mail Adresi</FormLabel>
                  <Input {...field} placeholder='E-Mail Adresi' _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.email as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {!teacher?.id && (
              <Field name='password'>
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={Boolean(form.errors.password && form.touched.password)}>
                    <FormLabel>Parola</FormLabel>
                    <Input {...field} placeholder='Parola' type='password' _dark={{ color: 'white' }} />
                    <FormHelperText>
                      Şifrenizde en az bir büyük harf, bir rakam ve bir noktalama işareti bulunmalıdır.
                    </FormHelperText>
                    <FormErrorMessage>{form.errors.password as string}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
