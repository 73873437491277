import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useDisclosure
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
// Assets
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import routes from 'routes';
import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { eraseCookie } from 'helpers/cookie-manager';
import { resetSession } from 'lib/slices/session/session-slice';
import { useNavigate } from 'react-router-dom';
import ControlledMenuItem from 'components/with-controlled/menu-item';
import { USER_ROLES } from 'constants/users';
import { ItemContent } from 'components/menu/ItemContent';
import { MdNotificationsNone, MdNotificationsActive } from 'react-icons/md';
import { markAsReadNotifications } from 'api/services/notifications';
import { resetNotifications } from 'lib/slices/notifications/notifications-slice';
import { useEffect, useRef } from 'react';

export default function HeaderLinks(props: { secondary: boolean }) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const redBrand = useColorModeValue('red.500', 'red.200');
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const session = useAppSelector((selector) => selector.session);
  const notifications = useAppSelector((selector) => selector.notifications);
  const wasOpen = useRef(false);

  const anyNotification = Array.isArray(notifications.notifications) && notifications.notifications.length > 0;

  const handleClickLogout = () => {
    eraseCookie('accessToken');
    dispatch(resetSession());
    window.location.href = '/panel/home';
  };

  const notificationsOnClick = async () => {
    if (anyNotification) {
      await markAsReadNotifications();
    }
  };

  useEffect(() => {
    if (wasOpen.current && !isOpen && anyNotification) {
      dispatch(resetNotifications());
    }
    wasOpen.current = isOpen;
  }, [isOpen]);

  return (
    <Flex
      // w={{ sm: '100%', md: 'auto' }}
      alignItems='center'
      flexDirection='row'
      gap={3}
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p='10px'
      justifyContent={{ sm: 'end', md: 'unset' }}
      borderRadius='30px'
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
        <MenuButton p='0px' onClick={notificationsOnClick}>
          <Icon
            mt='6px'
            as={anyNotification ? MdNotificationsActive : MdNotificationsNone}
            color={anyNotification ? redBrand : textColorBrand}
            w='18px'
            h='18px'
            me='10px'
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p='20px'
          borderRadius='20px'
          bg={menuBg}
          border='none'
          mt='22px'
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w='100%' mb='20px'>
            <Text fontSize='md' fontWeight='600' color={textColor}>
              Bildirimler
            </Text>
          </Flex>
          <Flex flexDirection='column'>
            {notifications.notifications.map((notification, key) => (
              <MenuItem key={key} bg='none' _focus={{ bg: '#ffffff0f' }} px='0' borderRadius='8px' mb='10px'>
                <ItemContent message={notification.message} />
              </MenuItem>
            ))}
          </Flex>
        </MenuList>
      </Menu>
      <Button
        variant='no-hover'
        bg='transparent'
        p='0px'
        minW='unset'
        minH='unset'
        h='18px'
        w='max-content'
        onClick={toggleColorMode}
      >
        <Icon me='10px' h='18px' w='18px' color={navbarIcon} as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />
      </Button>
      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color='white'
            name={session.username}
            bg='#11047A'
            size='sm'
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
          <Flex w='100%' mb='0px'>
            <Text
              ps='20px'
              pt='16px'
              pb='10px'
              w='100%'
              borderBottom='1px solid'
              borderColor={borderColor}
              fontSize='sm'
              fontWeight='700'
              color={textColor}
            >
              👋&nbsp; Hey, {session.nameSurname}
            </Text>
          </Flex>
          <Flex flexDirection='column' p='10px'>
            <ControlledMenuItem
              borderRadius='8px'
              px='14px'
              roles={[USER_ROLES.STUDENT]}
              onClick={() => navigate('/panel/profile')}
            >
              <Text fontSize='sm'>Profilim</Text>
            </ControlledMenuItem>
            <MenuItem
              bg='none'
              _focus={{ bg: '#ffffff0f' }}
              color='red.400'
              borderRadius='8px'
              px='14px'
              onClick={handleClickLogout}
            >
              <Text fontSize='sm'>Çıkış Yap</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
};
