import {
  Flex,
  Text,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useColorModeValue,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  Stack,
  Skeleton,
  Select,
  RadioGroup,
  Radio,
  IconButton
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper
} from '@tanstack/react-table';
import { DataTableProps } from './types';
import Card from 'components/card/Card';
import { useRef, useState } from 'react';
import { CiMenuKebab } from 'react-icons/ci';
import { useAppSelector } from 'lib/hooks';
import { USER_ROLES } from 'constants/users';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const innerBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  boxSize: 'full',
  color: 'white',
  textShadow: '0 0 20px black',
  fontWeight: 'bold',
  fontSize: '20px',
  width: '100%',
  height: '100%'
};

export default function DataTable<T>({
  loading,
  tableData,
  columns,
  handleEditClick,
  handleDeleteClick,
  onPaginationChangeCallback,
  totalCount,
  handleClickJoin,
  handleClickCancel,
  error,
  rightActionButtons,
  handleOnChangeParticipants,
  participants,
  displayAttendanceOptions,
  displayStudentsButton,
  displayRemoveFromReservation,
  handleClickRemoveFromReservation,
  displayProfileButton,
  displayReservationRestrictionButton,
  handleClickDisplayReservationRestrictionButton
}: DataTableProps<T>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [willBeDeleteId, setWillBeDeleteId] = useState(null);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const columnHelper = createColumnHelper<T>();
  const navigate = useNavigate();

  const role = useAppSelector((selector) => selector.session.role);

  const actionsMenuRequiredRoles = [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN];
  const managerRoles = [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN, USER_ROLES.TEACHER];

  const deleteClick = (id: string) => {
    setWillBeDeleteId(id);
    onOpen();
  };

  const deleteClickConfirm = () => {
    onClose();

    if (deleteClickConfirm) {
      const tempId = willBeDeleteId;

      setWillBeDeleteId(null);
      handleDeleteClick(tempId);
    }
  };

  const removeFromReservationClick = (id: string) => {
    setWillBeDeleteId(id);
    onOpen();
  };

  const removeFromReservationClickConfirm = () => {
    onClose();

    if (deleteClickConfirm) {
      const tempId = willBeDeleteId;

      setWillBeDeleteId(null);
      handleClickRemoveFromReservation(tempId);
    }
  };

  const actionsMenu = actionsMenuRequiredRoles.includes(role)
    ? columnHelper.display({
        id: 'action',
        header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
        cell: (info: any) => (
          <Flex align='center' w={'auto'}>
            <Menu>
              <MenuButton as={Button}>
                <Icon mt='6px' as={CiMenuKebab} color='brand.500' w='18px' h='18px' />
              </MenuButton>
              <MenuList minW='10rem'>
                <MenuItem onClick={() => handleEditClick(info.row.original.id)}>Düzenle</MenuItem>
                <MenuItem onClick={() => deleteClick(info.row.original.id)}>Sil</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ),
        size: 50,
        minSize: 30,
        maxSize: 50
      })
    : undefined;

  const joinButton =
    rightActionButtons && role === USER_ROLES.STUDENT
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              {info.row.original.isJoin ? (
                <Button colorScheme='red' onClick={() => handleClickCancel(info.row.original.id)}>
                  İptal
                </Button>
              ) : (
                <>
                  {info.row.original.capacity === info.row.original.madeReservationCount ? (
                    <Button colorScheme='telegram' opacity='0.6' pointerEvents='none'>
                      Dolu
                    </Button>
                  ) : (
                    <Button
                      colorScheme='telegram'
                      onClick={() => handleClickJoin(info.row.original.id, info.row.original.lessonName)}
                    >
                      Katıl
                    </Button>
                  )}
                </>
              )}
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const displayReservations =
    rightActionButtons && managerRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              <Button colorScheme='telegram' onClick={() => navigate(`/panel/reservations/${info.row.original.id}`)}>
                Görüntüle
              </Button>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const removeFromReservation =
    displayRemoveFromReservation && managerRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              <Button colorScheme='red' onClick={() => removeFromReservationClick(info.row.original.id)}>
                Rezervasyondan Çıkart
              </Button>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const displayStudents =
    displayStudentsButton && managerRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              <Button colorScheme='telegram' onClick={() => navigate(`/panel/classes/${info.row.original.id}`)}>
                Öğrencileri Görüntüle
              </Button>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const displayProfile =
    displayProfileButton && managerRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              <Button colorScheme='telegram' onClick={() => navigate(`/panel/profile/${info.row.original.id}`)}>
                Profili Görüntüle
              </Button>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const displayReservationRestriction =
    displayReservationRestrictionButton && actionsMenuRequiredRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'></Text>,
          cell: (info: any) => (
            <Flex align='center' w={'auto'}>
              <Button
                colorScheme='orange'
                variant='outline'
                onClick={() => handleClickDisplayReservationRestrictionButton(info.row.original.id)}
              >
                Kısıtlama Ayarları
              </Button>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const attendanceActions =
    displayAttendanceOptions && managerRoles.includes(role)
      ? columnHelper.display({
          id: 'action',
          header: () => (
            <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
              Yoklama Durumu
            </Text>
          ),
          cell: (info: any) => (
            <Flex align='center' w={'auto'} columnGap={5}>
              <RadioGroup
                defaultValue='1'
                onChange={(status) => handleOnChangeParticipants(info.row.original.studentId?.toString(), status)}
                value={participants?.includes(info.row.original.studentId?.toString()) ? '2' : '1'}
              >
                <Stack spacing={5} direction='row'>
                  <Radio colorScheme='red' value='1'>
                    Katılmadı
                  </Radio>
                  <Radio colorScheme='green' value='2'>
                    Katıldı
                  </Radio>
                </Stack>
              </RadioGroup>
            </Flex>
          ),
          size: 50,
          minSize: 30,
          maxSize: 50
        })
      : undefined;

  const tableColumns = [
    actionsMenu,
    ...columns.map((col) =>
      columnHelper.accessor(col.name as any, {
        id: col.name as string,
        header: () => (
          <Text align='center' fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
            {col.displayName || String(col.name)}
          </Text>
        ),
        cell: (info: any) => (
          <Flex align='center'>
            <Text color={textColor} fontSize='sm' fontWeight='700'>
              {info.getValue()}
            </Text>
          </Flex>
        )
      })
    ),
    joinButton,
    removeFromReservation,
    attendanceActions,
    displayReservations,
    displayReservationRestriction,
    displayStudents,
    displayProfile
  ].filter(Boolean);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    rowCount: totalCount,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    onPaginationChange: (updater) => {
      if (typeof updater !== 'function') return;

      const oldPageInfo = table.getState().pagination;
      const newPageInfo = updater(table.getState().pagination);

      setState((prevState) => ({
        ...prevState,
        pagination: newPageInfo
      }));

      if (!Boolean(oldPageInfo.pageIndex === newPageInfo.pageIndex && oldPageInfo.pageSize === newPageInfo.pageSize)) {
        onPaginationChangeCallback(newPageInfo.pageIndex, newPageInfo.pageSize);
      }
    }
  });

  const [state, setState] = useState(table.initialState);

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState
  }));

  return (
    <Box position='relative'>
      <Card flexDirection='column' w='100%' px='0px' maxH='600px' minH='600px' overflow='auto'>
        {error ? (
          <Box sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text boxShadow='xl' p={5} borderRadius={10} fontSize={'20px'} fontWeight={'bold'}>
              {error}
            </Text>
          </Box>
        ) : loading ? (
          <Stack p={5} gap={4}>
            <Skeleton height='40px' />
            <Skeleton height='40px' />
            <Skeleton height='40px' />
            <Skeleton height='40px' />
          </Stack>
        ) : (
          <Box position='relative'>
            <Table variant='simple' color='gray.500' mb='24px' mt='12px'>
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        width={header.id === 'action' ? '40px' : 'auto'}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <Td key={cell.id} fontSize={{ sm: '14px' }} borderColor='transparent'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Card>
      <Card mt={2}>
        <Flex justifyContent='space-between'>
          <Flex gap={5} alignItems='center'>
            <Select
              placeholder='Select option'
              w='auto'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Göster {pageSize}
                </option>
              ))}
            </Select>
            <Text>
              Sayfa {table.getState().pagination.pageIndex + 1} - {table.getPageCount()}
            </Text>
          </Flex>
          <Flex gap={5}>
            <IconButton
              isRound={true}
              variant='solid'
              colorScheme='teal'
              aria-label='Done'
              fontSize='20px'
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              icon={<ArrowBackIcon />}
            />
            <IconButton
              isRound={true}
              variant='solid'
              colorScheme='teal'
              aria-label='Done'
              fontSize='20px'
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              icon={<ArrowForwardIcon />}
            />
          </Flex>
        </Flex>
      </Card>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Emin misiniz?
            </AlertDialogHeader>

            <AlertDialogBody>
              {displayRemoveFromReservation
                ? 'Öğrenciyi bu rezervasyondan çıkartmak istediğinize emin misiniz?'
                : 'Silmek istediğiniz emin misiniz?'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                İptal
              </Button>

              {displayRemoveFromReservation ? (
                <Button colorScheme='red' onClick={removeFromReservationClickConfirm} ml={3}>
                  Evet
                </Button>
              ) : (
                <Button colorScheme='red' onClick={deleteClickConfirm} ml={3}>
                  Sil
                </Button>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
