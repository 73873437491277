import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Select } from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { CreateOrEditReservationModalProps, CreateOrEditReservationModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { ChangeEvent, useEffect, useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { CreateReservationRequest, UpdateReservationRequest } from 'api/services/reservations/types';
import { createReservation, updateReservation } from 'api/services/reservations';
import { TeachersResponse } from 'api/services/teachers/types';
import { getTeachers } from 'api/services/teachers';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';

export default function CreateOrEditReservationModal(props: CreateOrEditReservationModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { reservation, onSuccessCallback, lessonsOptions, salonsOptions } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [teachersOptions, setTeachersOptions] = useState([]);

  const formatDateForApi = (dateString: any) => {
    const date = new Date(dateString);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  };

  const handleSubmit = async (
    values: CreateOrEditReservationModalSubmit,
    { setSubmitting }: FormikHelpers<CreateOrEditReservationModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      const formattedStartDate = formatDateForApi(values.reservationStartDate);
      const formattedEndDate = formatDateForApi(values.reservationEndDate);

      if (reservation?.id) {
        const payload: UpdateReservationRequest = {
          teacherId: values.teacherId,
          lessonId: values.lessonId,
          reservationStartDate: formattedStartDate,
          reservationEndDate: formattedEndDate,
          salonId: values.salonId,
          capacity: values.capacity
        };

        const updateReservationResponse = await updateReservation(payload, reservation.id.toString());

        if (updateReservationResponse?.status === 400) {
          if (Array.isArray(updateReservationResponse.data)) {
            const errorMessages = updateReservationResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(updateReservationResponse.data);
        }

        if (updateReservationResponse.id) {
          snackbar('Başarıyla güncellendi', 'success');
          return onSuccessCallback();
        }

        throw new Error('Bir şeyler hatalı gitti');
      } else {
        const payload: CreateReservationRequest = {
          teacherId: values.teacherId,
          lessonId: values.lessonId,
          reservationStartDate: formattedStartDate,
          reservationEndDate: formattedEndDate,
          salonId: values.salonId,
          capacity: Number(values.capacity)
        };

        const createReservationResponse = await createReservation(payload);

        if (createReservationResponse?.status === 400) {
          if (Array.isArray(createReservationResponse.data)) {
            const errorMessages = createReservationResponse.data.map((error: any) => error.description).join(' ');
            throw new Error(errorMessages);
          }

          throw new Error(createReservationResponse.data);
        }

        if (!createReservationResponse.id) {
          throw new Error('Bir şeyler hatalı gitti');
        }

        snackbar('Başarıyla oluşturuldu', 'success');
        onSuccessCallback();
      }
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  const fetchAndSetTeachers = async (majorId: string) => {
    const teachersResponse: TeachersResponse = await getTeachers({
      ...PAGINATION_PARAMS_FOR_DROPDOWN,
      majorId
    });

    if (teachersResponse.teachers.length === 0) {
      setTeachersOptions([]);
      return;
    }

    const mappedTeachers = teachersResponse.teachers.map((item) => ({
      id: item.id,
      label: item.nameSurname,
      value: item.id.toString()
    }));

    setTeachersOptions(mappedTeachers);
  };

  const customOnChangeHandler = async (event: ChangeEvent<HTMLSelectElement>) => {
    const majorId = event.target?.value;

    await fetchAndSetTeachers(majorId);
  };

  useEffect(() => {
    if (reservation?.lessonId) {
      fetchAndSetTeachers(reservation.lessonId.toString());
    }
  }, []);

  return (
    <Formik
      initialValues={{
        teacherId: reservation?.teacherId,
        lessonId: reservation?.lessonId,
        reservationStartDate: reservation?.reservationStartDate || new Date(),
        reservationEndDate: reservation?.reservationEndDate || new Date(),
        salonId: reservation?.salonId,
        capacity: reservation?.capacity
      }}
      validationSchema={Yup.object().shape({
        teacherId: Yup.number().required('Öğretmen seçimi zorunlu'),
        lessonId: Yup.number().required('Ders seçimi zorunlu'),
        reservationStartDate: Yup.date()
          .min(new Date(), 'Başlangıç tarihi şu anki tarihten daha eski olamaz')
          .required('Başlangıç Tarih zorunlu'),
        reservationEndDate: Yup.date()
          .min(Yup.ref('reservationStartDate'), 'Bitiş tarihi, başlangıç tarihinden önce olamaz')
          .required('Bitiş Tarihi zorunlu'),
        salonId: Yup.number().required('Salon seçimi zorunlu'),
        capacity: Yup.number().required('Kapasite zorunlu')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Flex flexDirection='column' gap={3}>
            <Field name='lessonId'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.lessonId && form.touched.lessonId)}>
                  <FormLabel>Ders</FormLabel>
                  <Select
                    {...field}
                    _dark={{ color: 'white' }}
                    placeholder='Ders'
                    onChange={(e) => {
                      field.onChange(e);
                      customOnChangeHandler(e);
                    }}
                  >
                    {lessonsOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.lessonId as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='teacherId'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.teacherId && form.touched.teacherId)}>
                  <FormLabel>Öğretmen</FormLabel>
                  <Select
                    _dark={{ color: 'white' }}
                    {...field}
                    placeholder='Öğretmen'
                    disabled={teachersOptions.length === 0}
                  >
                    {teachersOptions?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.teacherId as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='salonId'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.salonId && form.touched.salonId)}>
                  <FormLabel>Salon</FormLabel>
                  <Select _dark={{ color: 'white' }} {...field} placeholder='Sınıf'>
                    {salonsOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.salonId as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='capacity'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.capacity && form.touched.capacity)}>
                  <FormLabel>Kapasite</FormLabel>
                  <Input {...field} placeholder='Kapasite' _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.capacity as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='reservationStartDate'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.reservationStartDate && form.touched.reservationStartDate)}>
                  <FormLabel>Tarih</FormLabel>
                  <Input {...field} type='datetime-local' value={field.value} _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.reservationStartDate as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name='reservationEndDate'>
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={Boolean(form.errors.reservationEndDate && form.touched.reservationEndDate)}>
                  <FormLabel>Tarih</FormLabel>
                  <Input {...field} type='datetime-local' value={field.value} _dark={{ color: 'white' }} />
                  <FormErrorMessage>{form.errors.reservationEndDate as string}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>

            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Kaydet
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
