import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  RadioGroup,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import {
  cancelReservationParticipation,
  deleteReservation,
  getReservation,
  reservationsLessons
} from 'api/services/reservations';
import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useEffect, useState } from 'react';
import { ReservationsRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { getReservations } from 'api/services/reservations';
import CreateOrEditReservationModal from 'components/reservations/create-or-edit-reservation-modal';
import { getLessons } from 'api/services/lessons';
import { Lesson } from 'api/services/lessons/types';
import { getSalons } from 'api/services/salons';
import { SalonsResponse } from 'api/services/salons/types';
import { getLessonsSubjects } from 'api/services/lessons-subjects';
import JoinReservationModal from 'components/reservations/join-reservation-modal';
import { Subjects } from 'api/services/lessons-subjects/types';
import { IoFilter } from 'react-icons/io5';
import ControlledMenuItem from 'components/with-controlled/menu-item';
import { ReservationLessonsResponse, ReservationsResponse } from 'api/services/reservations/types';
import { convertUTCToLocal } from 'helpers/date';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';

const columnsDataCheck: Column<ReservationsRowObj>[] = [
  { name: 'lessonName', displayName: 'Ders' },
  { name: 'teacherNameSurname', displayName: 'Öğretmen' },
  { name: 'salonName', displayName: 'Salon' },
  { name: 'displayCapacity', displayName: 'Kapasite' },
  { name: 'reservationStartDate', displayName: 'Başlangıç' },
  { name: 'reservationEndDate', displayName: 'Bitiş' },
  { name: 'status', displayName: 'Aktiflik' }
];

export default function Reservations() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  let menuBg = useColorModeValue('white', 'navy.800');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [lessonNames, setLessonNames] = useState([]);
  const [reservations, setReservations] = useState<ReservationsResponse>({ totalCount: 0, reservations: [] });
  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);

  // Filters
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isDisplayOwnReservation, setIsDisplayOwnReservation] = useState(false);

  const navigate = useNavigate();

  const getActiveStatus = (status: string) => {
    switch (status) {
      case 'Active':
        return 'Aktif';
      case 'Passed':
        return 'Geçmiş';
      case 'Canceled':
        return 'İptal';
    }
  };

  const fetchReservations = async (
    page?: number,
    pageSize?: number,
    getOwnReservations?: boolean,
    selectedLessonNames?: string[]
  ) => {
    try {
      const getReservationsParams = {
        page: page?.toString(),
        size: pageSize?.toString(),
        getOwnReservations: getOwnReservations,
        lessonName: Array.isArray(selectedLessonNames) && selectedLessonNames.length > 0 && selectedLessonNames
      };

      if (!selectedLessonNames || selectedLessonNames.length === 0) {
        delete getReservationsParams.lessonName;
      }

      if (typeof getOwnReservations === 'undefined') {
        delete getReservationsParams.getOwnReservations;
      }

      const reservationsResponse: ReservationsResponse = await getReservations(getReservationsParams);

      setLoading(false);

      if ((reservationsResponse as any)?.status === 400) {
        throw reservationsResponse;
      }

      const updatedReservations = {
        ...reservationsResponse,
        reservations: (reservationsResponse as ReservationsResponse).reservations.map((reservation) => ({
          ...reservation,
          displayCapacity: `${reservation.madeReservationCount}/${reservation.capacity}`,
          status: getActiveStatus(reservation.status)
        }))
      };

      setReservations(updatedReservations);
    } catch (error: any) {
      setError(error.data);
      console.log(error);
    }
  };

  const fetchActiveLessonsForFilter = async () => {
    const reservationLessonsResponse: ReservationLessonsResponse = await reservationsLessons();

    if (!Array.isArray(reservationLessonsResponse)) {
      return;
    }

    const mappedLessonNames = [...new Set(reservationLessonsResponse.map((item) => item.lessonName))];

    if (lessonNames.length === 0 && Array.isArray(mappedLessonNames) && mappedLessonNames.length > 0) {
      setLessonNames(mappedLessonNames);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchReservations(page, pageSize, isDisplayOwnReservation);
  };

  useEffect(() => {
    fetchReservations(page, pageSize, isDisplayOwnReservation);
  }, []);

  useEffect(() => {
    fetchActiveLessonsForFilter();
  }, []);

  const getDropdownOptions = async () => {
    try {
      const lessonsResponse: Lesson[] = await getLessons();
      const salonsResponse: SalonsResponse = await getSalons(PAGINATION_PARAMS_FOR_DROPDOWN);

      const mappedLessons = lessonsResponse?.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id.toString()
      }));

      const mappedSalons = salonsResponse?.salons?.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.id.toString()
      }));

      return { mappedLessons, mappedSalons };
    } catch (error) {
      console.log(error);

      return { mappedLessons: [], mappedSalons: [] };
    }
  };

  const handleClickCreateReservation = async () => {
    const { mappedLessons, mappedSalons } = await getDropdownOptions();

    showModal(
      <CreateOrEditReservationModal
        lessonsOptions={mappedLessons}
        salonsOptions={mappedSalons}
        onSuccessCallback={handleOnSuccessCallback}
      />,
      'Rezervasyon Oluştur'
    );
  };

  const handleEdit = async (id: number) => {
    const { mappedLessons, mappedSalons } = await getDropdownOptions();
    const reservationResponse = await getReservation(id.toString());

    const formattedReservation = {
      ...reservationResponse,
      reservationStartDate: convertUTCToLocal(reservationResponse.reservationStartDate),
      reservationEndDate: convertUTCToLocal(reservationResponse.reservationEndDate)
    };

    showModal(
      <CreateOrEditReservationModal
        reservation={formattedReservation}
        lessonsOptions={mappedLessons}
        salonsOptions={mappedSalons}
        onSuccessCallback={handleOnSuccessCallback}
      />,
      'Rezervasyon Düzenle'
    );
  };

  const handleDelete = async (id: number) => {
    try {
      const deleteResponse = await deleteReservation(id.toString());

      if (deleteResponse.status === 400) {
        if (Array.isArray(deleteResponse.data)) {
          const errorMessages = deleteResponse.data.map((error: any) => error.description).join(' ');
          throw new Error(errorMessages);
        }

        throw new Error(deleteResponse.data);
      }

      if ((deleteResponse as any).status) {
        throw new Error('Bir şeyler hatalı gitti');
      }

      snackbar('Başarıyla silindi', 'success');
      await fetchReservations(page, pageSize, isDisplayOwnReservation);
    } catch (error) {
      snackbar(error as string, 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchReservations(pageIndex + 1, pageSize);
  };

  const handleClickJoin = async (id: number, lessonName: string) => {
    const lessonSubjectsResponse: Subjects[] = await getLessonsSubjects({
      lessonName
    });

    const mappedLessonSubjects = lessonSubjectsResponse.map((item, key) => ({
      id: key,
      label: item.name,
      value: item.id.toString()
    }));

    showModal(
      <JoinReservationModal
        reservationId={id}
        lessonsSubjects={mappedLessonSubjects}
        onSuccessCallback={handleOnSuccessCallback}
      />,
      'Rezervasyonu Onayla'
    );
  };

  const handleClickCancel = async (id: number) => {
    try {
      await cancelReservationParticipation(id.toString());

      snackbar('Başarıyla iptal edildi', 'success');
      await fetchReservations(page, pageSize, isDisplayOwnReservation);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFilterMyReservation = async (checked: boolean) => {
    setIsDisplayOwnReservation(checked);

    await fetchReservations(page, pageSize, checked);
  };

  const handleCheckboxChange = (lesson: string) => {
    let newSelectedLessons = [...selectedLessons];

    if (selectedLessons.includes(lesson)) {
      newSelectedLessons = newSelectedLessons.filter((item) => item !== lesson);
    } else {
      newSelectedLessons.push(lesson);
    }

    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete('lesson');
      newSelectedLessons.forEach((lesson) => newParams.append('lesson', lesson));
      return newParams;
    });

    setSelectedLessons(newSelectedLessons);
  };

  useEffect(() => {
    const lessonFilters = searchParams.getAll('lesson');

    fetchReservations(page, pageSize, isDisplayOwnReservation, lessonFilters);
    setSelectedLessons(lessonFilters);
  }, [searchParams]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2} position='relative'>
        <Flex justifyContent='space-between' alignItems={'center'}>
          <Text w={'100%'} fontStyle={'italic'} fontWeight={'bold'}>
            Not: Randevu süresi 20 dk'dır
          </Text>
          <Flex justifyContent='end' w='100%' mb='10px'>
            <ControlledButton
              variant='outline'
              colorScheme='telegram'
              rightIcon={<AddIcon />}
              roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
              onClick={handleClickCreateReservation}
            >
              Yeni Ekle
            </ControlledButton>
            <Menu>
              <MenuButton p='0px'>
                <Button colorScheme='telegram' rightIcon={<IoFilter />} ml={5} onClick={handleClickCreateReservation}>
                  Filtreler
                </Button>
              </MenuButton>
              <MenuList boxShadow={shadow} mt='10px' borderRadius='20px' bg={menuBg} border='none'>
                <Flex flexDirection='column' p='10px'>
                  <ControlledMenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    px='14px'
                    closeOnSelect={false}
                    roles={[USER_ROLES.STUDENT]}
                  >
                    <Checkbox
                      checked={isDisplayOwnReservation}
                      onChange={(e) => handleChangeFilterMyReservation(e.target?.checked)}
                    >
                      Aktif Rezervasyonlarımı Göster
                    </Checkbox>
                  </ControlledMenuItem>
                  <ControlledMenuItem
                    _hover={{ bg: 'none' }}
                    _focus={{ bg: 'none' }}
                    borderRadius='8px'
                    w='100%'
                    closeOnSelect={false}
                    roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN, USER_ROLES.STUDENT]}
                  >
                    <Flex flexDirection={'column'} gap={3} w={'100%'}>
                      <Text pb='10px' w='100%' fontSize='md' fontWeight='700' borderBottom='1px solid #e0e0e0'>
                        Sadece şu dersleri göster
                      </Text>
                      {lessonNames.map((lessonName) => (
                        <Checkbox
                          checked={false}
                          isChecked={selectedLessons.includes(lessonName)}
                          onChange={() => handleCheckboxChange(lessonName)}
                        >
                          {lessonName}
                        </Checkbox>
                      ))}
                    </Flex>
                  </ControlledMenuItem>
                </Flex>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Box>
          <DataTable
            tableData={reservations.reservations}
            columns={columnsDataCheck}
            handleEditClick={handleEdit}
            handleDeleteClick={handleDelete}
            loading={loading}
            totalCount={reservations.totalCount}
            onPaginationChangeCallback={onPaginationChange}
            handleClickJoin={handleClickJoin}
            handleClickCancel={handleClickCancel}
            error={error}
            rightActionButtons
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
}
