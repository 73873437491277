import { useSnackbar } from 'notistack';

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  return (message: string, type: 'success' | 'error' | 'info' | 'warning', options = {}) => {
    enqueueSnackbar(message, {
      variant: type,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      },
      autoHideDuration: 3000,
      ...options
    });
  };
};
