import React, { FC, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { GenerateParticipantsFileProps } from './types';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: require('../../../assets/fonts/Roboto-Regular.ttf'), fontWeight: 'normal' }, // Normal stil
    { src: require('../../../assets/fonts/Roboto-Bold.ttf'), fontWeight: 'bold' } // Bold stil
  ]
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto'
  },
  table: {
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginTop: 10
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf'
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    backgroundColor: '#f3f3f3'
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
    textAlign: 'center'
  }
});
const GenerateParticipantsFile: FC<GenerateParticipantsFileProps> = ({ data }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            marginBottom: '15px'
          }}
        >
          <Image src={data.image} style={{ width: 'auto', height: '130px' }} />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {data.lessonName} Dersi Soru Çözüm Katılımcı Listesi
          </Text>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '15px' }}>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ fontSize: '13px' }}>
              <Text>Öğretmen</Text>
            </View>
            <View style={{ fontSize: '16px', fontWeight: 'bold' }}>
              <Text>{data.teacherName}</Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ fontSize: '13px' }}>
              <Text>Salon</Text>
            </View>
            <View style={{ fontSize: '16px', fontWeight: 'bold' }}>
              <Text>{data.salon}</Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ fontSize: '13px' }}>
              <Text>Tarih</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', fontSize: '16px', fontWeight: 'bold' }}>
              <Text>{data.date}</Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Adı Soyadı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Sınıfı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Çözülen Soru Sayısı</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableHeader}>Saat</Text>
            </View>
          </View>

          {data.participants.map((participant: any, index: number) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{participant.studentName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{participant.className}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default GenerateParticipantsFile;
