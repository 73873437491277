import { createHttpRequests } from 'api/requests';
import {
  TeachersResponse,
  CreateTeacherRequest,
  CreateTeacherResponse,
  UpdateTeacherRequest,
  UpdateTeacherResponse,
  GetTeacherResponse
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getTeachers = async (data?: {
  page: string;
  pageSize: string;
  majorId?: string;
}): Promise<TeachersResponse | any> => {
  try {
    const queryParams = {
      page: data.page || '1',
      pageSize: data.pageSize || '10',
      majorId: data.majorId
    };

    if (!data.majorId) {
      delete queryParams.majorId;
    }

    const response = await getRequest<TeachersResponse>(endpoints.teachers, queryParams);
    return response;
  } catch (error) {
    return error;
  }
};

export const getTeacher = async (id: string): Promise<GetTeacherResponse | any> => {
  try {
    const response = await getRequest<any>(endpoints.teacher(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const createTeacher = async (data: CreateTeacherRequest): Promise<CreateTeacherResponse | any> => {
  try {
    const response = await postRequest<CreateTeacherResponse>(endpoints.teachers, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTeacher = async (data: UpdateTeacherRequest, id: string): Promise<UpdateTeacherResponse | any> => {
  try {
    const response = await putRequest<UpdateTeacherResponse>(endpoints.teacher(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteTeacher = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.teacher(id));
    return response;
  } catch (error) {
    return error;
  }
};
