import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { deleteTeacher, getTeacher } from 'api/services/teachers';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useEffect, useState } from 'react';
import { TeachersRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { getTeachers } from 'api/services/teachers';
import CreateOrEditTeacherModal from 'components/teachers/create-or-edit-teacher-modal';
import { GetTeacherResponse, TeachersResponse } from 'api/services/teachers/types';
import { getLessons } from 'api/services/lessons';
import { Lesson } from 'api/services/lessons/types';

const columnsDataCheck: Column<TeachersRowObj>[] = [
  { name: 'nameSurname', displayName: 'Adı Soyadı' },
  { name: 'majorName', displayName: 'Branş' },
  { name: 'email', displayName: 'E-Mail Adresi' }
];

export default function Teachers() {
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [teachers, setTeachers] = useState<TeachersResponse>({ totalCount: 0, teachers: [] });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchTeachers = async (page?: number, pageSize?: number) => {
    try {
      const teachersResponse = await getTeachers({ page: page?.toString(), pageSize: pageSize?.toString() });

      setTeachers(teachersResponse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchTeachers(page, pageSize);
  };

  useEffect(() => {
    fetchTeachers(page, pageSize);
  }, []);

  const handleClickCreateTeacher = async () => {
    const lessons: Lesson[] = await getLessons();

    const mappedLessons = lessons.map((item) => ({
      id: item.id,
      label: item.name,
      value: item.name
    }));

    showModal(
      <CreateOrEditTeacherModal lessonsOptions={mappedLessons} onSuccessCallback={handleOnSuccessCallback} />,
      'Öğretmen Oluştur'
    );
  };

  const handleEdit = async (id: number) => {
    try {
      const teacherResponse: GetTeacherResponse = await getTeacher(id.toString());
      const lessons: Lesson[] = await getLessons();

      const mappedLessons = lessons.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.name
      }));

      showModal(
        <CreateOrEditTeacherModal
          teacher={teacherResponse}
          lessonsOptions={mappedLessons}
          onSuccessCallback={handleOnSuccessCallback}
        />,
        'Öğretmen Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteTeacher(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await fetchTeachers(page, pageSize);
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchTeachers(pageIndex + 1, pageSize);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
            onClick={handleClickCreateTeacher}
          >
            Yeni Ekle
          </ControlledButton>
        </Flex>
        <DataTable
          tableData={teachers.teachers}
          columns={columnsDataCheck}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
          loading={loading}
          totalCount={teachers.totalCount}
          onPaginationChangeCallback={onPaginationChange}
        />
      </SimpleGrid>
    </Box>
  );
}
