import { Box, Card, Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import { useEffect, useState } from 'react';
import { ClassesResponse, GetClassResponse } from 'api/services/classes/types';
import { getClass, getClasses } from 'api/services/classes';
import { useNavigate, useParams } from 'react-router-dom';
import { StudentClassRowObj } from './types';
import { GetStudentResponse } from 'api/services/students/types';
import { deleteStudent, getStudent } from 'api/services/students';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';
import CreateOrEditStudentModal from 'components/students/create-or-edit-student-modal';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { useUI } from 'contexts/UIContext';

const columnsDataCheck: Column<StudentClassRowObj>[] = [
  { name: 'nameSurname', displayName: 'Adı Soyadı' },
  { name: 'email', displayName: 'E-Mail Adresi' }
];

export default function ClassDetail() {
  const { id } = useParams();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const { showModal } = useUI();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [classData, setClassData] = useState<GetClassResponse>();

  const fetchClass = async () => {
    try {
      const classResponse = await getClass(id.toString());

      if ((classResponse as any).status === 404) {
        navigate('/panel/classes');
      }

      setClassData(classResponse);
    } catch (error: any) {
      setError(error.data);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (id: number) => {
    try {
      const studentResponse: GetStudentResponse = await getStudent(id.toString());
      const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

      const mappedClasses = classesResponse.classes.map((item) => ({
        id: item.id,
        label: `${item.number}/${item.branchName}`,
        value: item.id.toString()
      }));

      showModal(
        <CreateOrEditStudentModal
          student={studentResponse}
          classesOptions={mappedClasses}
          onSuccessCallback={fetchClass}
        />,
        'Öğrenci Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteStudent(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await fetchClass();
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  useEffect(() => {
    if (id) {
      fetchClass();
    }
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card p='20px' borderRadius='20px'>
        <Flex justifyContent='center' columnGap={10}>
          <Box>
            <Text fontSize='sm'>Sınıf</Text>
            <Text fontSize='lg' fontWeight='bold'>
              {classData?.class.number}/{classData?.class.branchName}
            </Text>
          </Box>
          <Box>
            <Text fontSize='sm'>Kapasite</Text>
            <Text fontSize='lg' fontWeight='bold'>
              {classData?.class.capacity}
            </Text>
          </Box>
        </Flex>
      </Card>
      <SimpleGrid spacing={2} position='relative' mt={10}>
        <Box>
          <DataTable
            tableData={classData?.students || []}
            columns={columnsDataCheck}
            loading={loading}
            error={error}
            handleEditClick={handleEdit}
            handleDeleteClick={handleDelete}
          />
        </Box>
      </SimpleGrid>
    </Box>
  );
}
