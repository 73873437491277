import { FC } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { ControlledMenuItemProps } from './types';
import { useAppSelector } from 'lib/hooks';

const ControlledMenuItem: FC<ControlledMenuItemProps> = ({ module, roles, ...menuItemProps }) => {
  const organizationModules = useAppSelector((selector) => selector.session.modules);
  const userRole = useAppSelector((selector) => selector.session.role);

  const isAllowed =
    (!module || organizationModules.includes(module)) && (!Array.isArray(roles) || roles.includes(userRole));

  if (!isAllowed) {
    return null;
  }

  return <MenuItem {...menuItemProps} />;
};

export default ControlledMenuItem;
