import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionState } from './types';

const initialState: SessionState = {
  isAuthenticated: false,
  role: '',
  modules: [],
  email: '',
  username: '',
  organization: undefined,
  isLoading: true,
  nameSurname: ''
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionState>) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.role = action.payload.role;
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.organization = action.payload.organization;
      state.modules = action.payload.modules;
      state.isLoading = false;
      state.nameSurname = action.payload.nameSurname;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetSession: () => {
      return initialState;
    }
  }
});

export const { setSession, setIsLoading, resetSession } = sessionSlice.actions;

export default sessionSlice.reducer;
