import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useState, useEffect } from 'react';
import { ClassesRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { deleteClass, getClass, getClasses } from 'api/services/classes';
import CreateOrEditClassModal from 'components/classes/create-or-edit-class-modal';
import { ClassesResponse } from 'api/services/classes/types';
import ReservationRestrictionModal from 'components/classes/reservation-restriction-modal';

const columnsDataCheck: Column<ClassesRowObj>[] = [
  { name: 'number', displayName: 'Sınıf' },
  { name: 'branchName', displayName: 'Şube Adı' },
  { name: 'capacity', displayName: 'Kapasite' }
];

export default function Teachers() {
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState<ClassesResponse>({ totalCount: 0, classes: [] });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchClasses = async (page?: number, pageSize?: number) => {
    try {
      const lessonsResponse = await getClasses({ page: page?.toString(), pageSize: pageSize?.toString() });

      setClasses(lessonsResponse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchClasses(page, pageSize);
  };

  useEffect(() => {
    fetchClasses(page, pageSize);
  }, []);

  const handleClickCreateClass = () => {
    showModal(<CreateOrEditClassModal onSuccessCallback={handleOnSuccessCallback} />, 'Sınıf Oluştur');
  };

  const handleEdit = async (id: number) => {
    const classResponse = await getClass(id.toString());

    showModal(
      <CreateOrEditClassModal classData={classResponse.class} onSuccessCallback={handleOnSuccessCallback} />,
      'Sınıf Düzenle'
    );
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteClass(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await fetchClasses(page, pageSize);
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const handleClickDisplayReservationRestriction = async (id: number) => {
    try {
      showModal(
        <ReservationRestrictionModal onSuccessCallback={handleOnSuccessCallback} classId={id.toString()} />,
        'Rezervasyon Kısıtlaması Ekle'
      );
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchClasses(pageIndex + 1, pageSize);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
            onClick={handleClickCreateClass}
          >
            Yeni Ekle
          </ControlledButton>
        </Flex>
        <DataTable
          tableData={classes.classes}
          columns={columnsDataCheck}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
          loading={loading}
          onPaginationChangeCallback={onPaginationChange}
          totalCount={classes.totalCount}
          displayReservationRestrictionButton
          handleClickDisplayReservationRestrictionButton={handleClickDisplayReservationRestriction}
          displayStudentsButton
        />
      </SimpleGrid>
    </Box>
  );
}
